/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { ReactComponent as ScoutWhite } from 'src/assets/icons/scout-white.svg';
import { ChevronLeft, ChevronRight, Settings } from '@mui/icons-material';
import {
    Box,
    Paper,
    useTheme,
    useMediaQuery,
    SxProps,
} from '@mui/material';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { DataType } from 'src/models/dataType.enum';
import { ImageItem } from '../grid-image-view/gridmodal';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { SettingsPanel } from '../settings/SettingsPanel';
import { ControlButton } from '../Buttons/ControlButton';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgressWithLabel } from '../progressbar/CircularProgressWithLabel';
import {
    CROSS_ORIGIN,
    MP4_EXTENSION,
    MP4_MIME,
    VIDEO_BATCH_SIZE,
    VIDEO_BITS_PER_SECOND,
    VIDEO_HEIGHT,
    VIDEO_WIDTH,
    WEBM_EXTENSION,
    WEBM_MIME,
    WEBM_MIME_CODECS,
} from 'src/utils/environment';
import { PhotoItem } from '../grid-image-view';
interface SliderProps {
  images: ImageItem[] | PhotoItem[];
}
interface HTMLCanvasElementWithCaptureStream extends HTMLCanvasElement {
    captureStream(frameRate?: number): MediaStream;
}

export interface SettingsPanelProps
  {
    panelRef?:{current: HTMLDivElement | null;}
    timeInterval: number;
    setTimeInterval: (value: number) => void;
    showSlider: boolean;
    setShowSlider: (value: boolean) => void;
  }
export interface ControlButtonProps
{
  children: React.ReactNode;
  onClick?: () => void; sx?: SxProps }

export function ImageSlider({ images }: Readonly<SliderProps>): JSX.Element
{
    const [index, setIndex] = useState(0);
    const [batchIndex, setBatchIndex] = useState(0);
    const batchSize = 5;
    const [imageBatch, setImageBatch] = useState<PhotoItem[]|ImageItem[]>(images.slice(index, batchSize));
    const [timeInterval, setTimeInterval] = useState(1000);
    const [isPlaying, setIsPlaying] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [showSlider, setShowSlider] = useState(false);
    const canvasRef = useRef<HTMLCanvasElementWithCaptureStream | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [progress, setProgress] = React.useState(0);
    const [progressVisible, setProgressVisible] = React.useState(false);
    const panelRef = useRef<HTMLDivElement | null>(null);
    const cancelRef = useRef(false);
    useEffect(() =>
    {
        const handleClickOutside = (event: MouseEvent):void =>
        {
            if (panelRef.current && !panelRef.current.contains(event.target as Node))
            {
                setSettingsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () =>
        {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [settingsVisible]);
    useEffect(()=>
    {
        setImageBatch(images.slice(batchIndex, batchIndex+batchSize));
    }, [images, batchIndex]);

    const handleNext = useCallback(() =>
    {
        if(index < imageBatch.length -1)
        {
            setIndex((prev) => (prev + 1));
        }
        else
        {
            const newBatchStart = batchIndex + batchSize;
            if(newBatchStart < images.length)
            {
                setBatchIndex(newBatchStart);
                setIndex(0);
            }
            else
            {
                setIndex(0);
                setBatchIndex(0);
                setIsPlaying(false);
            }

        }
    }, [batchIndex, imageBatch, index, images]);


    const handlePrev = useCallback(() =>
    {
        if(index > 0)
        {
            setIndex((prev) =>(prev - 1 ));
        }
        else
        {
            const newBatchStart = batchIndex - batchSize;
            if(newBatchStart >=0)
            {
                setIndex(batchSize-1);
                setBatchIndex(newBatchStart);
            }
        }
    }, [batchIndex, index, images]);

    useEffect(() =>
    {
        if (!isPlaying) return;
        const slider = setInterval(handleNext, timeInterval);
        return () => clearInterval(slider);
    }, [isPlaying, timeInterval, handleNext]);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isMacChrome = navigator.userAgent.includes('Macintosh') && navigator.userAgent.includes('Chrome');

    const getMimeType = (): string =>
    {
        if(isMacChrome)
        {
            return WEBM_MIME_CODECS;
        }
        else if(MediaRecorder.isTypeSupported(MP4_MIME))
        {
            return MP4_MIME;
        }
        else
        {
            return WEBM_MIME;
        }
    };

    const downloadVideo = (blob: Blob, batchNumber: number): void =>
    {
        const safeMimeType = getMimeType();
        const fileExtension = safeMimeType.includes(MP4_EXTENSION)? MP4_EXTENSION : WEBM_EXTENSION;
        const url = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${images[0]?.device ?? 'video'}_part_${batchNumber + 1}.${fileExtension}`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        setTimeout(() => URL.revokeObjectURL(url), 5000);
    };

    const handleSafariDownload = (videoBlob: Blob, batchNumber: number): void =>
    {
        const reader = new FileReader();
        reader.readAsDataURL(videoBlob);
        reader.onloadend = () => downloadVideo(videoBlob, batchNumber);
    };

    const loadImage = (src: string): Promise<HTMLImageElement> =>
    {
        return new Promise((resolve, reject) =>
        {
            const img = new Image();
            img.crossOrigin = CROSS_ORIGIN;
            img.src = src;

            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    };

    const processFrame = (
        canvas: HTMLCanvasElementWithCaptureStream,
        ctx: CanvasRenderingContext2D,
        frameIndex: number
    ): Promise<void> =>
    {
        return loadImage(images[frameIndex].fileUrl).then((img) =>
        {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const imgRatio = img.width / img.height;
            const canvasRatio = canvas.width / canvas.height;
            let drawWidth:number, drawHeight:number;
            if(imgRatio > canvasRatio)
            {
                drawHeight = canvas.height;
                drawWidth = canvas.width;
            }
            else
            {
                drawHeight = canvas.height;
                drawWidth = canvas.height * imgRatio;
            }

            const horizontal = (canvas.width -drawWidth)/2;
            const vertical =( canvas.height -drawHeight)/2;

            ctx.drawImage(img, horizontal, vertical, drawWidth, drawHeight);

            setProgress(() => Math.min(100, ((frameIndex + 1) / images.length) * 100));
        });
    };

    const renderFrames = (
        canvas: HTMLCanvasElementWithCaptureStream,
        ctx: CanvasRenderingContext2D,
        startIndex: number,
        endIndex: number,
        recorder: MediaRecorder
    ): Promise<void> =>
    {
        return new Promise((resolve) =>
        {
            let frameIndex = startIndex;

            const frameInterval = setInterval(async () =>
            {
                if (cancelRef.current)
                {
                    clearInterval(frameInterval);
                    recorder.stop();
                    setProgressVisible(false);
                    return resolve();
                }
                if (frameIndex > endIndex || frameIndex >= images.length)
                {
                    clearInterval(frameInterval);
                    setTimeout(() =>
                    {
                        recorder.stop();
                        resolve();
                    }, 1000);
                    return;
                }

                await processFrame(canvas, ctx, frameIndex);
                frameIndex++;
            }, timeInterval);
        });
    };

    const processBatch = async (canvas: HTMLCanvasElementWithCaptureStream, ctx: CanvasRenderingContext2D, startIndex: number, endIndex: number, batchNumber: number): Promise<void> =>
    {
        return new Promise((resolve) =>
        {
            if (cancelRef.current) return resolve();
            const stream = canvas.captureStream(60);
            const chunks: Blob[] = [];
            const mimeType = getMimeType();

            const recorder = new MediaRecorder(stream, {
                mimeType,
                videoBitsPerSecond: VIDEO_BITS_PER_SECOND,
            });

            recorder.ondataavailable = (event) =>
            {
                if (event.data.size > 0) chunks.push(event.data);
            };

            recorder.onstop = () =>
            {
                if (chunks.length === 0 || cancelRef.current)
                {
                    resolve();
                    return;
                }

                const videoBlob = new Blob(chunks, { type: mimeType });
                isSafari ? handleSafariDownload(videoBlob, batchNumber) : downloadVideo(videoBlob, batchNumber);
                resolve();
            };

            recorder.start();
            renderFrames(canvas, ctx, startIndex, endIndex, recorder).then(() => recorder.stop());
        });
    };

    const processAllBatches = async (canvas: HTMLCanvasElementWithCaptureStream, ctx: CanvasRenderingContext2D): Promise<void> =>
    {
        let batchStartIndex = 0;
        let batchNumber = 0;

        while (batchStartIndex < images.length)
        {
            const batchEndIndex = Math.min(batchStartIndex + VIDEO_BATCH_SIZE - 1, images.length - 1);
            await processBatch(canvas, ctx, batchStartIndex, batchEndIndex, batchNumber);
            batchStartIndex = batchEndIndex + 1;
            batchNumber++;
        }
    };

    const generateVideo = async (): Promise<void> =>
    {
        cancelRef.current = false;
        if(progressVisible)
        {
            cancelRef.current = true;
        }
        setProgress(0);

        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        setProgressVisible(true);
        await processAllBatches(canvas, ctx);

        if (!cancelRef.current)
        {
            setProgress(100);
        }
        setProgressVisible(false);
    };



    const currentImage = useMemo(() => imageBatch[index], [imageBatch, index]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 3,
            }}
        >
            {/* Main Slider Container */}
            <canvas
                ref={canvasRef}
                width={VIDEO_WIDTH}
                height={VIDEO_HEIGHT}
                style={{ display: 'none' }}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: isMobile ? '100%' : '80%',
                    position: 'relative',
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {/* Image Display */}
                {currentImage
                    ? (
                        <Paper
                            key={currentImage.id}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 3,
                                width: isMobile ? '90%' : '80%',
                                textAlign: 'center',
                                boxShadow: 3,
                                position: 'relative',
                                transition: 'all 0.5s ease',
                                '&:hover': { boxShadow: 6 },
                                backgroundColor: theme.palette.common.shadowBlack,
                            }}
                        >
                            <Box
                                component="img"
                                src={currentImage.fileUrl}
                                alt={currentImage.fileUrl}
                                sx={{
                                    width: isMobile ? '80%' : '100%',
                                    height: isMobile ? '40vh' : '70vh',
                                    borderRadius: 2,
                                    mb: 2,
                                    objectFit: 'contain',
                                    transition: 'all 0.1s ease',
                                }}
                                onClick={() => setIsPlaying(!isPlaying)}
                            />

                            {/* Controls */}
                            {hovered && (
                                <>
                                    <ControlButton onClick={handlePrev} sx={{ left: 0 }}>
                                        <ChevronLeft fontSize="large" />
                                    </ControlButton>
                                    <ControlButton onClick={handleNext} sx={{ right: 0 }}>
                                        <ChevronRight fontSize="large" />
                                    </ControlButton>
                                    <ControlButton
                                        onClick={() => setIsPlaying(!isPlaying)}
                                        sx={{
                                            bottom: 5,
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                        }}
                                    >
                                        {isPlaying
                                            ? <PauseCircleOutlineIcon fontSize="large" />
                                            : <PlayCircleOutlineIcon fontSize="large" />}
                                    </ControlButton>
                                    <ControlButton
                                        onClick={() =>
                                            setSettingsVisible(!settingsVisible)}
                                        sx={{ bottom: 5, right: 5 }}
                                    >
                                        <Settings fontSize="large" />
                                    </ControlButton>
                                    <ControlButton
                                        onClick={() =>
                                            generateVideo()}
                                        sx={{ bottom: 5, right: 50 }}
                                    >
                                        {progressVisible ? (<CircularProgressWithLabel value={progress}/>)
                                            : (<DownloadIcon fontSize="large" />)
                                        }
                                    </ControlButton>
                                    {currentImage.aiScanStatus === 2 && (
                                        <ControlButton
                                            sx={{
                                                top: 10,
                                                left: 15,
                                                width: '2.5rem',
                                                height: '2.5rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '&:hover': {
                                                    transform: 'scale(1.1)',
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    borderRadius: '50%',
                                                    width: '2rem',
                                                    height: '2rem',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <ScoutWhite />
                                            </Box>
                                        </ControlButton>
                                    )}


                                    <ControlButton
                                        sx={{ top: 15, right: 15 }}
                                    >

                                        <DataTypeFlagItem
                                            getIds={(images) => [images.id]}
                                            capturedData={currentImage}
                                            dataType={
                                                DataType.SCREENSHOTS_META}
                                        />

                                    </ControlButton>
                                </>
                            )}

                            {/* Settings Panel */}
                            {settingsVisible && (
                                <SettingsPanel
                                    panelRef={panelRef}
                                    timeInterval={timeInterval}
                                    setTimeInterval={setTimeInterval}
                                    showSlider={showSlider}
                                    setShowSlider={setShowSlider}
                                />
                            )}
                        </Paper>
                    ):null}

            </Box>
        </Box>

    );
}



