/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import {
    MapContainer,
    Marker,
    Polyline,
    Popup,
    TileLayer,
    Tooltip,
    useMapEvents,
} from 'react-leaflet';
import { GeolocationData } from 'src/models/captures/geolocationData.model';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconMarkerSelected from 'src/assets/icons/marker-selected-icon.png';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { IconButton, TextField, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { compareDates } from 'src/utils/dateUtils';
import Box from '@mui/system/Box';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getGeolocationslistDevice,
    changeGeolocation,
    selectGeolocationslistDevice,
    selectGeolocationslistDeviceStatus,
    selectFilters,
    selectGeolocationsByDate,
    getGeolocationsByDate,
    changeColumns,
    changeGeolocationFilter,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { CommentType } from 'src/models/commentType.enum';
import { DataDetailComment } from '../data-type-detail/DataDetailComment';
import { ALERTSPAGE } from 'src/models/alertsType.enum';

const icon = L.icon({
    iconRetinaUrl: iconMarker,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
    iconSize: [25, 35],
    iconAnchor: [15, 48],
});

const selectedicon = L.icon({
    iconRetinaUrl: iconMarkerSelected,
    iconUrl: iconMarkerSelected,
    shadowUrl: iconShadow,
    iconSize: [38, 52],
    iconAnchor: [20, 48],
});

interface LocationMarkerProps {
    readonly data: Readonly<GeolocationData>;
}

function LocationMarker({ data }: LocationMarkerProps): React.ReactElement
{
    const [showPopup, setShowPopup] = useState(false);

    const map = useMapEvents({
        click()
        {
            map.flyTo([data.latitude, data.longitude], map.getZoom());
        },
    });

    const handleMarkerClick = (): void =>
    {
        setShowPopup(true);
    };
    return (
        <>
            <Marker
                position={[data.latitude, data.longitude]}
                icon={selectedicon}
                eventHandlers={{
                    click: handleMarkerClick,
                }}
            />
            {showPopup && (
                <Popup
                    position={[data.latitude, data.longitude]}
                    onClose={() => setShowPopup(false)}
                >
                    <div>
                        {new Date(data.capturedDate)?.toLocaleString()}
                    </div>
                </Popup>
            )}
        </>
    );
}

export function GeolocationDetails(data: Readonly<GeolocationData>): React.ReactElement
{
    const dispatch = useDispatch();
    const initialDate = new Date(data.receivedDate);
    initialDate.setMinutes(0);
    const [date, setDate] = useState(initialDate);
    const [map, setMap] = useState<L.Map>();
    const geolocationsByDate = useSelector(selectGeolocationsByDate);
    const limeOptions = { color: 'lime' };
    const commentsData = {
        contentId: data?.id,
        type: CommentType.GEOLOCATION,
    };

    const alertsPage = window.location.pathname === ALERTSPAGE;

    const polyline = geolocationsByDate?.map((geolocation) => [
        geolocation.latitude,
        geolocation.longitude,
    ]) as [number, number][];

    const { t } = useTranslation();

    const filters = useSelector(selectFilters);

    const geolocationslistDeviceStatus = useSelector(
        selectGeolocationslistDeviceStatus
    );
    const geolocationlistDeviceData = useSelector(selectGeolocationslistDevice);

    const handleOpen = (data: GeolocationData): void =>
    {
        dispatch(changeGeolocation(data));
    };

    const [entriesPerPage, setEntriesPerPage] = useState(10);

    function onChangeDateFilter(newdate: Date): void
    {
        setDate(newdate);
    }

    function onChangeDetails(data: GeolocationData): void
    {
        dispatch(changeGeolocation(data));
    }

    useEffect(() =>
    {
        if (geolocationsByDate?.length)
        {
            const geolocation = geolocationsByDate[0];
            if (compareDates(new Date(data.receivedDate), new Date(geolocation.receivedDate)))
            {
                dispatch(changeGeolocation(geolocation));
            }
        }
    }, [dispatch, geolocationsByDate]);

    useEffect(() =>
    {
        dispatch(getGeolocationsByDate({ date, deviceId: data.id }));
    }, [date, dispatch, filters]);

    useEffect(() =>
    {
        dispatch(
            getGeolocationslistDevice({
                date,
                device: alertsPage ? data.device : data.id,
                startDate: filters.startDate,
                endDate: filters.endDate,
                sortBy: filters.sortBy,
                pageNumber: 1,
                entriesPerPage,
            })
        );

        dispatch(
            changeGeolocationFilter({
                date,
                device: alertsPage ? data.device : data.id,
                startDate: filters.startDate,
                endDate: filters.endDate,
                sortBy: filters.sortBy,
                pageNumber: 1,
                entriesPerPage,
            })
        );
    }, [date, dispatch, entriesPerPage, filters]);

    useEffect(() =>
    {
        if (map)
        {
            map.flyTo([data.latitude, data.longitude], map.getZoom());
        }
    }, [data, dispatch]);

    function onPageChange(pageNumber: number): void
    {
        dispatch(
            getGeolocationslistDevice({
                date,
                device: alertsPage ? data.device : data.id,
                startDate: filters.startDate,
                endDate: filters.endDate,
                sortBy: filters.sortBy,
                pageNumber,
                entriesPerPage,
            })
        );
    }

    const openDetailColumn = (data: GeolocationData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: GeolocationData) =>
                new Date(data.capturedDate)?.toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: GeolocationData) =>
                new Date(data.receivedDate)?.toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 8,
            value: (data: GeolocationData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.latitude'),
            size: 2,
            value: (data: GeolocationData) => data.latitude,
            sortByType: 'latitude',
        },
        {
            label: t('data-type.table.longitude'),
            size: 2,
            value: (data: GeolocationData) => data.longitude,
            sortByType: 'longitude',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: GeolocationData) => openDetailColumn(data),
        },
    ];

    const formData = geolocationlistDeviceData?.contents.map(item => ({
        dataType: 'Geolocation',
        ...item,
    }));
    useEffect(()=>
    {
        dispatch(changeColumns(columns));
    }, [columns]);
    return (
        <>
            <Box
                sx={{
                    maxWidth: '48%',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        right: 220,
                        top: -22,
                        zIndex: 999,
                        display: !geolocationsByDate ||
                        geolocationsByDate?.length ? 'none' : 'block',
                        color: '#F37B7B',
                    }}
                >
                    {t('data-type.details.geolocation-unavailable-date')}
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        right: 5,
                        top: 12,
                        zIndex: 999,
                        border: '1px solid #cccccc',
                        padding: 1,
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        display: 'flex',
                        width: '305px',
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={date ?? null}
                            onChange={(newValue) => onChangeDateFilter(newValue as Date)}
                            renderInput={(params) => (
                                <TextField
                                    sx={{
                                        backgroundColor: (theme: Theme) =>
                                            theme.palette.common.white,
                                    }}
                                    {...params}
                                    onKeyDown={(e)=>e.preventDefault()}
                                />
                            )}
                        />
                        <TimePicker
                            value={date ?? null}
                            onChange={(newValue) => onChangeDateFilter(newValue as Date)}
                            minutesStep={60}
                            views={['hours']}
                            renderInput={(params) => (
                                <TextField
                                    sx={{
                                        ml: 2,
                                        backgroundColor: (theme: Theme) =>
                                            theme.palette.common.white,
                                    }}
                                    {...params}
                                    onKeyDown={(e) => e.preventDefault()}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
                {
                    geolocationsByDate ? (
                        <MapContainer
                            center={[data.latitude, data.longitude]}
                            zoom={5}
                            style={{ width: '100%', height: '100%' }}
                            whenCreated={setMap}
                        >
                            <TileLayer
                                attribution='<a href="https://www.remotecom.com/copyright">RemoteCom</a>'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Polyline pathOptions={limeOptions} positions={polyline} />
                            {
                                geolocationsByDate?.map(geolocation =>
                                {
                                    return (
                                        <Marker
                                            key={geolocation.id}
                                            position={[
                                                geolocation?.latitude, geolocation?.longitude,
                                            ]}
                                            icon={icon}
                                            eventHandlers={{
                                                click: (e: object) => onChangeDetails(geolocation),
                                            }}
                                        >
                                            <Tooltip>
                                                { new Date(geolocation.capturedDate)
                                                    ?.toLocaleString()
                                                }
                                            </Tooltip>
                                        </Marker>
                                    );
                                })
                            }
                            { geolocationsByDate?.length ? <LocationMarker data={data}/> : '' }
                        </MapContainer>
                    ) : (
                        ''
                    )}
            </Box>

            <Box
                sx={{
                    maxWidth: '45%',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: '52%',
                }}
            >
                {useDesktopScreen() ? (
                    <DataTable<GeolocationData>
                        isLoading={geolocationslistDeviceStatus === RequestStatus.InProgress}
                        columns={columns}
                        onPageChange={onPageChange}
                        entriesPerPage={entriesPerPage}
                        onChangeEntriesPerPage={setEntriesPerPage}
                        {...geolocationlistDeviceData}
                    />
                ) : (
                    <DataTableMobile
                        isLoading={geolocationslistDeviceStatus === RequestStatus.InProgress}
                        contents={formData || []}
                        setOpenModal={handleOpen}
                        onChangeEntriesPerPage={setEntriesPerPage}
                        entriesPerPage={entriesPerPage}
                        onPageChange={onPageChange}
                        numberOfEntries={geolocationlistDeviceData?.numberOfEntries}
                        currentPage={geolocationlistDeviceData?.currentPage}
                        enablePagination
                    />
                )}
                <div
                    style={{
                        position: 'absolute',
                        maxWidth: '75%',
                        width: '100%',
                        top: '105%',
                        right: '120%',
                    }}
                >
                    <DataDetailComment
                        {...commentsData}
                    />
                </div>
            </Box>
        </>

    );
}


