import { Box, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DomainScroller, SidebarMenu } from 'src/components/menu-option/SidebarMenu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeDomain,
    getDomains,
    selectAlertsFilter,
    selectDomains,
    selectDomainSearchValue,
    selectFilters,
} from 'src/state/captures/capturesSlice';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
import { DataType } from 'src/models/dataType.enum';
import { DOMAIN_LIST_LIMIT } from 'src/utils/environment';
export interface MenuOptions{
    name:string;
    value:string;
}
export interface MiniDrawerProps
{
    children: React.ReactNode;
    dataType: DataType;
    isAlertsPage?:boolean
}

const toggleButtonStyle = {
    position: 'absolute',
    top: 0,
    left: -10,
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid #ccc',
    transition: 'left 0.3s ease-in-out',
    width: '24px',
    height: '24px',
    zIndex: 1300,
    '&:hover': {
        backgroundColor: '#DEAD35',
        color: 'white',
        boxShadow: 'none',
    },
};
const sidebarMenuStyle = {
    boxShadow: (theme: Theme) => theme.shadows[3],
    borderColor: (theme:Theme)=>theme.palette.common.lightGrey,
    marginLeft: '-2rem',
    borderRadius: '5px',
    paddingRight: '0.5rem',
};

export function MiniDrawer({
    children, dataType,
}:Readonly<MiniDrawerProps>):React.ReactElement
{
    const alertsPage = window.location.pathname === ALERTSPAGE;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const medium = useMediaQuery(theme.breakpoints.down('md'));
    const mediumScreenSidebarWidth = isMobile || medium ? '100%': '15%';
    const mediumScreenMainWidth = isMobile || medium ? '0%': '80%';
    const dispatch = useDispatch();
    const domainSearchValue = useSelector(selectDomainSearchValue);
    const viewDataFilters = useSelector(selectFilters);
    const alertsFilter = useSelector(selectAlertsFilter);
    const domains = useSelector(selectDomains);
    const [activeDomain, setActiveDomain] = React.useState<MenuOptions|null>(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

    useEffect(()=>
    {
        dispatch(changeDomain(activeDomain?.value ?? undefined));
    }, [dispatch, activeDomain]);

    useEffect(()=>
    {
        if(alertsPage)
        {
            dispatch(getDomains({
                ...alertsFilter,
                dataType,
                domainSearchValue,
                entriesPerPage: DOMAIN_LIST_LIMIT,
                pageNumber: 1,
            }));
            setActiveDomain(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, domainSearchValue, alertsFilter]);
    useEffect(()=>
    {
        if(!alertsPage) {
            dispatch(getDomains({
                ...viewDataFilters,
                dataType,
                domainSearchValue,
                entriesPerPage: DOMAIN_LIST_LIMIT,
                pageNumber: 1,
            }));
            setActiveDomain(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,
        viewDataFilters.startDate,
        viewDataFilters.endDate,
        viewDataFilters.device,
        viewDataFilters.agency,
        viewDataFilters.officer,
        viewDataFilters.client,
        viewDataFilters.isDateFilterChanged,
        viewDataFilters.isDisableRecords,
        viewDataFilters.profileValue,
        viewDataFilters.flags,
        viewDataFilters.searchValue,
        domainSearchValue]);
    
    useEffect(()=>{
        setPageNumber(1);
    }, [domainSearchValue]);

    const handleMenuChange = (menu: MenuOptions):void=>
    {
        setActiveDomain((prev)=>prev?.name === menu?.name ? null : menu);
    };
    const toggleSidebar =():void =>
    {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const loadMoreDomains = (direction: DomainScroller): void =>
    {
        const filters = alertsPage ? alertsFilter: viewDataFilters;
        if(direction === DomainScroller.down)
        {
            setPageNumber((prev) => prev + 1);
            dispatch(getDomains({
                ...filters,
                dataType,
                domainSearchValue,
                entriesPerPage: DOMAIN_LIST_LIMIT,
                pageNumber: pageNumber + 1,
            }));
        }
        else if (direction === DomainScroller.up && pageNumber > 1)
        {
            setPageNumber((prev) => prev - 1);
            dispatch(getDomains({
                ...filters,
                dataType,
                domainSearchValue,
                entriesPerPage: DOMAIN_LIST_LIMIT,
                pageNumber: pageNumber - 1,
            }));
        }
    };
    return (
        <Box
            display={'flex'}
            sx={{
                maxHeight: '80vh',
                justifyContent: 'space-between',
                gap: '12px',
            }}
        >
            <Box
                width={isSidebarOpen ? mediumScreenSidebarWidth : '0px'}
                sx={{
                    ...sidebarMenuStyle,
                    overflowX: isMobile || medium ? '' : 'hidden',
                    transition: 'width 0.3s ease-in-out',
                    visibility: isSidebarOpen ? 'visible' : 'hidden',
                }}
            >
                <SidebarMenu
                    menuOptions={domains?.map(
                        (menu) => ({
                            name: `${menu.domain}(${menu.total})`,
                            value: menu.domain,
                        })
                    ) ?? []}
                    activeMenu={activeDomain}
                    handleMenuChange={handleMenuChange}
                    loadMore={loadMoreDomains}
                    isSidebarOpen={isSidebarOpen}
                />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    position: 'relative',
                    width: isSidebarOpen ? mediumScreenMainWidth : '',
                }}
            >
                {children}
                <IconButton
                    onClick={toggleSidebar}
                    sx={{ ...toggleButtonStyle }}
                >
                    {isSidebarOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </Box>
        </Box>
    );
}
