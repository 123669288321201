
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import { Box, CircularProgress, LinearProgress, TextField, Tooltip, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SidebarMenuList } from './SidebarMenuList';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { MenuOptions } from 'src/templates/sidebar/MiniDrawer';
import { changeDomainSearchValue, selectDomainStatus } from 'src/state/captures/capturesSlice';
import { DEBOUNCE_DELAY_TIME, DOMAIN_LIST_LIMIT } from 'src/utils/environment';
import debounceSearch from 'src/utils/common';
import { useTranslation } from 'react-i18next';

export enum DomainScroller {
    up,
    down
}

interface SidebarMenuProps {
    menuOptions: MenuOptions[]
    handleMenuChange: (menu:MenuOptions) => void
    activeMenu: MenuOptions|null;
    loadMore: (direction: DomainScroller) => void
    isSidebarOpen: boolean
}

export function SidebarMenu({
    menuOptions, activeMenu, handleMenuChange, loadMore, isSidebarOpen,
}: Readonly<SidebarMenuProps>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loadingState, setLoadingState] = useState<DomainScroller | null>(null); 
    const [scrollPosition, setScrollPosition] = useState<number | null>(null);
    const domainListStatus = useSelector(selectDomainStatus);
    const listRef = useRef<HTMLUListElement>(null);
    const isProcessing = useRef(false);

    const debouncedDomainSearch = (value: string): void => {
        dispatch(changeDomainSearchValue(value));
    };
    const debouncedValue = debounceSearch(debouncedDomainSearch, DEBOUNCE_DELAY_TIME);
    
    useEffect(()=>{
        if(domainListStatus === RequestStatus.Success && listRef.current) {
            const { clientHeight } = listRef.current;
            if(loadingState === DomainScroller.down) {
                listRef.current.scrollTop = 20;
            } else if(loadingState === DomainScroller.up) {
                listRef.current.scrollTop = listRef.current.scrollHeight - clientHeight - 20;
            }
        }
    }, [domainListStatus === RequestStatus.InProgress]);

    const resetDomainRefProcessing = (): void => {
        setTimeout(() => {
            isProcessing.current = false;
        }, 3000);
    }

    useEffect(() => {
        const handleScroll = (): void => {
            if (!listRef.current || !isSidebarOpen) return;
            const listElement = listRef.current;
            const { scrollTop, scrollHeight, clientHeight } = listElement;
            setScrollPosition(scrollTop);
            if (isProcessing.current) return;
            if (scrollPosition && scrollTop <= 10) {
                isProcessing.current = true;
                setLoadingState(DomainScroller.up);
                loadMore(DomainScroller.up);
                resetDomainRefProcessing();
            } else if (scrollTop + clientHeight >= scrollHeight - 10) {
                isProcessing.current = true;
                if(menuOptions.length === DOMAIN_LIST_LIMIT) {
                    loadMore(DomainScroller.down);
                }
                setLoadingState(DomainScroller.down);
                resetDomainRefProcessing();
            }
        };

        const listElement = listRef.current;
        if (listElement) {
            listElement.addEventListener('scroll', handleScroll);
        }
        return () => listElement?.removeEventListener('scroll', handleScroll);
    }, [loadMore, menuOptions]);

    useEffect(() => {
        if (isSidebarOpen && listRef.current && scrollPosition) {
            setTimeout(() => {
                listRef.current!.scrollTop = scrollPosition;
            }, 100);
        }
    }, [isSidebarOpen]);

    return (
        <>
            <Box
                sx={{
                    fontWeight: '700',
                    fontSize: '1rem',
                    textAlign: 'center',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: (theme) => theme.palette.common.white,
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 1rem'
                }}
            >
                {/* Domain List Title */}
                <Typography
                    variant="h5"
                    sx={{
                        borderBottom: '3px solid',
                        borderColor: (theme) => theme.palette.common.goldYellow,
                        padding: '8px',
                        width: '100%',
                        textAlign: 'center',
                        mb: '0.5rem',
                    }}
                >
                    Domain List
                </Typography>

                {/* Search Input Box */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '2px solid',
                        borderColor: (theme) => theme.palette.common.lightGrey,
                        padding: '4px 8px',
                        borderRadius: '4px',
                        width: '100%',
                    }}
                >
                    <TextField
                        variant="standard"
                        size="small"
                        placeholder="Search..."
                        onChange={(event) => debouncedValue(event.target.value)}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        sx={{
                            flex: 1,
                            marginRight: '8px',
                        }}
                    />
                    <SearchIcon
                        sx={{ color: (theme) => theme.palette.common.lightGrey }}
                    />
                </Box>
            </Box>
            <Box>
                <List ref={listRef} sx={{ maxHeight: '470px', overflowY: 'auto', position: 'relative' }}>

                    {(menuOptions.length === 0 && domainListStatus === RequestStatus.InProgress) ? (
                        <CircularProgress sx={{ margin: '4rem calc((100% - 40px) / 2)' }} />
                    )
                        : (
                            <>
                                {(loadingState === DomainScroller.up && domainListStatus === RequestStatus.InProgress) && (
                                    <Box sx={{ width: '100%', position: 'sticky', top: 0, zIndex: 2 }}>
                                        <LinearProgress />
                                    </Box>
                                )}
                                {menuOptions.length === 0 ? (
                                    <Box sx={{ textAlign: 'center', padding: '16px', color: 'gray' }}>
                                        {t('data-type.table.no-results')}
                                    </Box>
                                ) : (
                                    menuOptions.map((menu) =>
                                        menu.name.length > 20 ? (
                                            <Tooltip key={menu.name} title={menu.name} arrow>
                                                <span>
                                                    <SidebarMenuList
                                                        key={menu.name}
                                                        menu={menu}
                                                        handleMenuChange={handleMenuChange}
                                                        activeMenu={activeMenu}
                                                    />
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <SidebarMenuList
                                                key={menu.name}
                                                menu={menu}
                                                handleMenuChange={handleMenuChange}
                                                activeMenu={activeMenu}
                                            />
                                        )
                                    )
                                )}
                                {(loadingState === DomainScroller.down && domainListStatus === RequestStatus.InProgress) && (
                                    <Box sx={{ width: '100%', position: 'sticky', bottom: 0, zIndex: 2 }}>
                                        <LinearProgress />
                                    </Box>
                                )}
                            </>
                        )}
                </List>
            </Box>
        </>
    );
}

