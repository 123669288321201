import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAlertsData,
    getActiveDevices,
    getalerts,
    getCallHistory,
    getEmails,
    getExternalDevices,
    getFilesTracking,
    getGeolocations,
    getInActiveDevices,
    getInstalledPrograms,
    getKeystrokes,
    getLogonEvents,
    getPhotos,
    getPrintedDocuments,
    getProcesses,
    getRecord,
    getRedFlagged,
    getScreenCaptures,
    getScreenshots,
    getSearches,
    getTextMMSs,
    getTextSMSs,
    getWebsites,
    resetPhotosData,
    resetscreenCapturesData,
    resettextMMSsData,
    selectAlertsFilter,
    selectDomain,
    selectFilters,
    selectTableDatatype,
} from 'src/state/captures/capturesSlice';
import { DataType } from 'src/models/dataType.enum';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getDevicesCacheDetails, selectDeviceCacheSearchValue} from 'src/state/administrationMeta/administrationMetaSlice';
export function RefreshTable(): React.ReactElement
{
    const dispatch = useDispatch();
    const dataType = useSelector(selectTableDatatype);
    const filters = useSelector(selectFilters);
    const domain = useSelector(selectDomain);
    const alertsFilter = useSelector(selectAlertsFilter);
    const searchValue = useSelector(selectDeviceCacheSearchValue)
    const { t } = useTranslation();

    async function handlerRefresh(): Promise<void>
    {
        const rowsPerPage = filters?.rowsPerPage ?? 10;
        switch (dataType)
        {
        case DataType.KEYWORDS:
            dispatch(
                getKeystrokes({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.CALLS:
            dispatch(
                getCallHistory({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.EMAILS:
            dispatch(
                getEmails({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.PROCESSES:
            dispatch(
                getProcesses({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.EXTERNAL_MEDIA:
            dispatch(
                getExternalDevices({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.FILES_TRACKING:
            dispatch(
                getFilesTracking({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.GEOLOCATION:
            dispatch(
                getGeolocations({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.LOGON_EVENTS:
            dispatch(
                getLogonEvents({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.MMS:
            dispatch(
                getTextMMSs({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            dispatch(resettextMMSsData());
            break;
        case DataType.PHOTOS:
            dispatch(
                getPhotos({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
               
            );
            dispatch(
                resetPhotosData()
            );
           
            break;
        case DataType.PRINTED_DOCS:
            dispatch(
                getPrintedDocuments({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.PROGRAMS:
            dispatch(
                getInstalledPrograms({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.SCREEN_CAPTURES:
            dispatch(
                getScreenCaptures({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            dispatch(resetscreenCapturesData());
            break;
        case DataType.SCREENSHOTS:
            dispatch(
                getScreenshots({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.WEBSITES_SEARCH:
            dispatch(
                getSearches({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                    domain
                })
            );
            break;
        case DataType.SMS:
            dispatch(
                getTextSMSs({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                })
            );
            break;
        case DataType.WEBSITES:
            dispatch(
                getWebsites({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                    domain
                })
            );
            break;
        case DataType.DEVICE_CACHE:
            dispatch(
                getDevicesCacheDetails({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    searchValue,
                })
            );
            break;
        case DataType.ALERTS:
            dispatch(changeAlertsData());
            dispatch(
                getalerts({
                    pageNumber: 1,
                    entriesPerPage: rowsPerPage,
                    ...alertsFilter,
                    domain
                })
            );
            dispatch(
                getRedFlagged({
                    pageNumber: 0,
                    entriesPerPage: 0,
                    ...alertsFilter,
                })
            );
            dispatch(
                getActiveDevices({
                    agencyId: alertsFilter.agency,
                    officerId: alertsFilter.officer,
                    clientId: alertsFilter.client,
                    pageNumber: 1,
                    entriesPerPage: 10,
                })
            );
            dispatch(
                getInActiveDevices({
                    agencyId: alertsFilter.agency,
                    officerId: alertsFilter.officer,
                    clientId: alertsFilter.client,
                    pageNumber: 1,
                    entriesPerPage: 10,
                    details: false,
                })
            );
            dispatch(getRecord());
            break;
        default:
            break;
        }
    }
    return (
        <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handlerRefresh}
        >
            <RefreshIcon
                style={{
                    fontSize: '1rem',
                    marginRight: '0.5rem',
                }}
            />
            {t('common.button.refresh-button')}
        </Button>
    );
}
