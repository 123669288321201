import { CaptureParams } from 'src/models/captures/capturedData.model';
import { DomainList, WebsiteData } from 'src/models/captures/websiteData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { readAPIDomains, readAPIWebsitesResponse } from '../data-transfer-objects/capture/websites';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';

export async function getWebsites(
    captureParams: CaptureParams
): Promise<PaginatedContent<WebsiteData>>
{
    const response = await instance.get(
        'websites',
        { params: transformCaptureParams(captureParams) }
    );

    return readAPIPaginatedResponse(response.data, readAPIWebsitesResponse);
}

export async function getWebsite (id: string): Promise<WebsiteData>
{
    const response = await instance.get(`websites/${id}`).then(x => x.data);
    return readAPIWebsitesResponse(response);
}

export async function getDomains ( captureParams: CaptureParams): Promise<DomainList[]>
{
    const response = await instance.get(
        'websites/domain/list',
        {
            params: transformCaptureParams(captureParams),
        }
    );
    return readAPIDomains(response.data);
}
