import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DropdownSelector } from 'src/components/dropdown-selector/DropdownSelector';
import { AdminSettings } from 'src/models/adminSettings.enum';
import {
    clearSearchValue,
    selectAdminSettingsMenu,
    setAdminSettingsMenu,
    resetSendDeleteExtensionStatus
} from 'src/state/administration/administrationSlice';
import { clearDeviceInfoSearchValue } from 'src/state/administrationMeta/administrationMetaSlice';



export function AdministrationSettingsSelect(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const adminSettingMenu = useSelector(selectAdminSettingsMenu);

    function handleTabChange(value: unknown): void
    {
        dispatch(setAdminSettingsMenu(value as AdminSettings));
        dispatch(clearSearchValue());
        dispatch(clearDeviceInfoSearchValue());
        dispatch(resetSendDeleteExtensionStatus());
    }

    const menuItems = [
        {
            value: AdminSettings.SuggestedAlertWords,
            name: t('settings.suggested-alert-words'),
        },
        {
            value: AdminSettings.ProfileManagement,
            name: t('menu.profile-management'),
        },
        {
            value: AdminSettings.Users,
            name: t('settings.users-management'),
        },
        {
            value: AdminSettings.AgentManagement,
            name: t('settings.agent-management'),
        },
        {
            value: AdminSettings.VersionManagement,
            name: t('settings.version-management'),
        },
        {
            value: AdminSettings.Extensions,
            name: t('settings.extension-management'),
        },
        {
            value: AdminSettings.DeviceConfigurationManagement,
            name: t('settings.device-configuration-management'),
        },
        {
            value: AdminSettings.Instruction,
            name: t('menu.instruction'),
        },
        {
            value: AdminSettings.DeviceInformation,
            name: t('settings.device-information'),
        },
        {
            value: AdminSettings.DeviceCacheManagement,
            name: t('settings.device-cache-management'),
        },
    ];

    return (
        <DropdownSelector
            options={menuItems}
            fullWidth
            label={t('menu.admin-settings')}
            onChange={handleTabChange}
            value={adminSettingMenu}
        />
    );
}
