import React from 'react';
import { EmailData }
    from 'src/models/captures/emailData.model';
import 'react-image-gallery/styles/css/image-gallery.css';

export function EmailDetails(
    data: Readonly<EmailData>
): React.ReactElement
{
    return (
        <>
            {/* <div dangerouslySetInnerHTML={{ __html: data.emailBody }} /> */}
            <iframe
                title="my-iframe"
                width="100%"
                height="500px"
                frameBorder={'none'}
                srcDoc={data.emailBody}
                sandbox=""
            />
        </>
    );
}
