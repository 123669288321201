import React, { useRef, useState } from 'react';
import GridView from 'src/components/grid-image-view';
import {
    getAllToExportDatas,
    getTextMMSJPEG,
    getTextMMSs,
    resetAlertsFilterData,
    resettextMMSsData,
    selectFilters,
    selectGalleryFilters,
    selectTextMMSs,
    selectViewDataIdStatus,
    selectViewDataIds,
    selectViewDataJPEG,
    selectViewDataJPEGStatus,
} from 'src/state/captures/capturesSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TextMMSData } from 'src/models/captures/textMMSData.model';
import { DataType } from 'src/models/dataType.enum';
import DownloadIcon from '@mui/icons-material/Download';
import {
    TableExportFormat,
    usePDFExport,
    usePDFExportSetter,
    useTableExporter,
} from 'src/components/table-exporter/TableExporterProvider';
import { Button, CircularProgress, Grid } from '@mui/material';
import { downloadImages } from 'src/utils/common';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { PDFExport } from '../common-export-form/PDFExport';
import { JPEGExport } from '../common-export-form/JPEGExport';
import { GalleryViewHeader } from 'src/components/grid-image-view/GalleryViewHeader';

export default function TextMMSAlertsGrid({
    showDetails,
}: {
    readonly showDetails: (textMMSData: TextMMSData) => void
}): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const textMMSs = useSelector(selectTextMMSs);
    const filters = useSelector(selectFilters);
    const viewDataIds = useSelector(selectViewDataIds);
    const viewDataIdStatus = useSelector(selectViewDataIdStatus);
    const viewDataJPEG = useSelector(selectViewDataJPEG);
    const ViewDataJPEGstatus = useSelector(selectViewDataJPEGStatus);
    const pdfExportSetter = usePDFExportSetter();
    const pdfExporter = usePDFExport();
    const { setExportFormat } = useTableExporter();
    const [selectedImages, setSelectedImages] = React.useState<Array<TextMMSData>>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfType, setPdfType] = React.useState('');
    const [alltoExport, setAlltoExport]=useState(false);
    const galleryFilters = useSelector(selectGalleryFilters);

    React.useEffect(() =>
    {
        dispatch(resetAlertsFilterData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSetSelectedImages = (
        photoData: TextMMSData,
        checked: boolean
    ): void =>
    {
        if (photoData)
        {
            if (checked)
            {
                setSelectedImages((prevSelectedImages: TextMMSData[]) => [
                    ...prevSelectedImages,
                    photoData,
                ]);
                setSelectedFileIds((prevSelectedFileIds: string[]) => [
                    ...prevSelectedFileIds,
                    photoData.fileId,
                ]);
            }
            else
            {
                setSelectedImages((prevSelectedImages: TextMMSData[]) =>
                    prevSelectedImages.filter(
                        (selectedImage) => selectedImage.fileId !== photoData.fileId
                    ));
                setSelectedFileIds((prevSelectedFileIds: string[]) =>
                    prevSelectedFileIds.filter(
                        (fileId) => fileId !== photoData.fileId
                    ));
            }
        }
    };

    async function handlerAllPDFExport(): Promise<void>
    {
        setFormatAll('PDF');
        dispatch(getAllToExportDatas({
            ...filters,
            pageNumber: 1,
            entriesPerPage: 10,
            exportSkipId: 0,
            exportlimit:textMMSs?.numberOfEntries,
            pdfExport: true,
            dataType:DataType.MMS,
        }));
        setAlltoExport(true)
    }

    const handleALLJPEGExport = (): void =>
    {
        setIsLoading(true);
        dispatch(getTextMMSJPEG({
            ...filters,
        }));
    };

    const handleSelectPDF = (): void =>
    {
        if (selectedImages.length > 0)
        {
            pdfExportSetter({
                type: DataType.SMS,
                recordIds: selectedImages.map((item) => item.id),
            });
            setPdfType('selected');
        }
    };

    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        if (tableExportFormat === TableExportFormat.PDF && pdfExporter)
        {
            await TableExporter.asPDF(pdfExporter);
        }
        else
        {
            setExportFormat(tableExportFormat);
        }
        setFormat(undefined);
    }

    React.useEffect(() =>
    {
        if (pdfType)
        {
            handlerPDFExport(TableExportFormat.PDF);
        }
        setPdfType('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfType]);

    const prevFilters = useRef(filters);

    React.useEffect(() =>
    {
        if(filters.isGridView === true)
        {
            const filtersChanged = checkFilterChange(filters, prevFilters.current);

            if(!filtersChanged)
            {
                dispatch(resettextMMSsData());
            }

            getTextMMSs({
                pageNumber: 1,
                entriesPerPage: 10,
                ...filters,
            });
            prevFilters.current = filters;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <>

            <Grid container mb="1rem" mt="rem">
                <Grid item md={6} xs={12}>
                    <PDFExport
                        selectedValue={handlerAllPDFExport}
                        ids={viewDataIds}
                        idStatus={viewDataIdStatus}
                        data={DataType.SMS}
                        formatAll={formatAll}
                        setFormatAll={setFormatAll}
                        alltoExport={alltoExport}
                    />
                    <JPEGExport
                        selectedValue={handleALLJPEGExport}
                        ids={viewDataJPEG}
                        idStatus={ViewDataJPEGstatus}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                    {selectedImages.length > 0 ? (
                        <>
                            <Button
                                variant="text"
                                startIcon={
                                    TableExporter.loading
                                        && format === TableExportFormat.PDF
                                        ? <CircularProgress size="1rem" />
                                        : <DownloadIcon />
                                }
                                onClick={(e) => {
                                    handleSelectPDF();
                                }}
                            >
                                {t('common.button.selected-to-pdf')}
                            </Button>
                            <Button
                                variant="text"
                                startIcon={
                                    selectLoading
                                        ? <CircularProgress size="1rem" />
                                        : <DownloadIcon />
                                }
                                onClick={() => {
                                    downloadImages(
                                        selectedImages,
                                        null,
                                        setSelectLoading
                                    );
                                }}
                            >
                                {t('common.button.selected-to-jpeg')}
                            </Button>
                        </>
                    )
                        : null}
                </Grid>
                <GalleryViewHeader
                    dataType={DataType.MMS}
                    count={textMMSs?.numberOfEntries ?? 0}
                />
            </Grid>
            <GridView
                images={
                    textMMSs?.contents?.map((MMS, index) => ({
                        fileId: MMS.fileId,
                        date: MMS.capturedDate,
                        fileUrl: MMS.fileUrl,
                        count: index + 1,
                        totalCount: textMMSs?.numberOfEntries,
                        onClick: () => showDetails(MMS),
                        onCheck: handleSetSelectedImages,
                        aiScanStatus: MMS.aiScanStatus,
                        alertWords: MMS.alertWords || [],
                        device: MMS.device,
                        deviceId: MMS.deviceId,
                        platform: MMS.platform,
                        username: MMS.username,
                        flag: MMS.flag,
                        dataType: DataType.MMS,
                        id: MMS.id,
                        captureStartDate: MMS.captureStartDate,
                        captureEndDate: MMS.captureEndDate,
                        capturedDate: MMS.capturedDate,
                        receivedDate: MMS.receivedDate,
                        comments: MMS.comments,
                    })) || []
                }
                selectedFileIds={selectedFileIds}
                slideshow={galleryFilters.slideshow}
            />
        </>
    );
}
