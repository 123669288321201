import React from 'react';
import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { MenuOptions } from 'src/templates/sidebar/MiniDrawer';
interface SidebarMenuListProps {
    menu: MenuOptions
    handleMenuChange: (menu:MenuOptions) => void
    activeMenu: MenuOptions|null;
}

export function SidebarMenuList({
    menu, activeMenu, handleMenuChange,
}:Readonly<SidebarMenuListProps>):JSX.Element
{
    return (
        <ListItem
            key={menu.name}
            disablePadding
            sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minHeight: '1rem',
            }}
        >
            <ListItemButton
                selected={activeMenu?.name === menu.name}
                onClick={() => handleMenuChange(menu)}
                sx={(theme) => ({
                    borderRadius: '4px',
                    transition: 'all 0.3s ease-in-out',
                    color: theme.palette.text.primary,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                        borderRadius: '4px',
                    },
                    '&.Mui-selected': {
                        color: theme.palette.common.darkGrey,
                        backgroundColor: theme.palette.common.goldOrange,
                        borderRadius: '4px',
                        fontWeight: 600,
                        '&:hover': {
                            backgroundColor: theme.palette.common.goldOrange,
                        },
                    },
                })}
            >
                <ListItemText
                    primary={(
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ArrowRightOutlinedIcon />
                            <span>
                                {menu.name}
                            </span>
                        </Box>
                    )}
                    sx={{ '& .MuiListItemText-primary': { fontWeight: 500 } }}
                />
            </ListItemButton>
        </ListItem>
    );
}
