import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, SnackbarProps, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { noop } from 'src/utils/common';
import {
    deleteSuggestedAWL,
    selectAWLDeleteStatus,
    resetDeleteSuggestedAWLStatus,
} from 'src/state/administration/administrationSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import ConfirmationPanel from 'src/templates/confirmation-panel/ConfirmationPanel';
import { Modal } from 'src/components/modal/Modal';
interface SuggestedAWLConfirmationFormProps {
    id?: string;
    DeleteModal?: () => void;
    open: boolean
    type:string
}

function DeleteSuggestedAWL({
    id,
    DeleteModal = noop,
    open,
    type,
}: Readonly<SuggestedAWLConfirmationFormProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const deleteStatus = useSelector(selectAWLDeleteStatus);
    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }
    useEffect(() => {

        let message = '';

        if (deleteStatus === RequestStatus.Success) {
            message = t('user.suggestedawl-deleted');
        }
        else if (deleteStatus === RequestStatus.Failure) {
            message = t('common.messages.something-wrong');
        }
        if (message) {

            setNotificationState({ open: true, message });
        }

    }, [deleteStatus, setNotificationState, t, dispatch]);
    
    async function DeleteSubmit(): Promise<void>
    {
        if (id)
        {
            dispatch(deleteSuggestedAWL(id));
            setTimeout(() =>
            {
                dispatch(resetDeleteSuggestedAWLStatus());
                DeleteModal();
            }, 3000);
        }
    }

    return (
        <>
            <Modal
                title={t('menu.delete-suggestedawl')}
                open={open}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <ConfirmationPanel
                        data={type}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Suggested Alert words'}
                    />
                </Box>
            </Modal>

            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={deleteStatus === RequestStatus.Failure ? 'error' : 'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default DeleteSuggestedAWL;
