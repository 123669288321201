/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownSelector } from 'src/components/dropdown-selector/DropdownSelector';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    clientRemoteNoError,
    getAllProfiles,
    selectAllProfiles,
    selectAllProfilesRetrieveStatus,
    selectClientSaveStatus,
} from 'src/state/administration/administrationSlice';
import {
    getAgencyFilterOptions,
    getOfficerFilterOptions,
    selectAgencyFilterOptions,
    selectOfficerFilterOptions,
    selectAgencyFilterOptionsStatus,
    selectOfficerFilterOptionsStatus,
} from 'src/state/captures/capturesSlice';
import { Profile } from 'src/models/administration/profileData.model';
import { noop, validateEmail } from 'src/utils/common';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';
import { FilterOption } from 'src/models/common/filterOptionData.model';
import { OfficersParams } from 'src/models/administration/clientData.model';
import { Agency } from 'src/models/administration/agencyData.model';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from 'src/components/modal/Modal';
import ClientDetailsModel from '../client-details-model/ClientDetailsModel';

const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

export interface ClientFormValues {
    id?: string
    firstName: string
    lastName: string
    email: string
    agencyId: string
    officerId: string
    agencyInfo: Agency
    profiles: string[]
    officers: OfficersParams[]
    cellPhone: string[]
    remoteCOMAccount: string
    disableClient: boolean
    deleted?: boolean
}

export const DEFAULT_VALUES: ClientFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    agencyId: '',
    officerId: '',
    agencyInfo: {
        id: '',
        agencyName: '',
        enabled: false,
        description: '',
        logo: '',
        logoUrl: '',
    },
    profiles: [],
    officers: [],
    cellPhone: [],
    remoteCOMAccount: '',
    disableClient: false,
};

interface ClientFormProps {
    buttonLabel: string
    loading?: boolean
    initialValues?: ClientFormValues
    onSubmit?: (data: ClientFormValues) => void
    onDelete?: (data: ClientFormValues) => void
    onCancel?: () => void
}

interface FormErrors {
    [key: string]: string[] | undefined
}

export function ClientForm({
    buttonLabel = '',
    loading = false,
    initialValues = DEFAULT_VALUES,
    onSubmit = noop,
    onDelete = noop,
    onCancel = noop,
}: ClientFormProps): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [values, setValues] = useState<ClientFormValues>(initialValues);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const agencies = useSelector(selectAgencyFilterOptions);
    const agenciesStatus = useSelector(selectAgencyFilterOptionsStatus);
    const officerFilters = useSelector(selectOfficerFilterOptions);
    const officerFiltersStatus = useSelector(selectOfficerFilterOptionsStatus);
    const [officers, setOfficers] = useState<(FilterOption | null)[]>([]);
    const clientRemoteNoErrors = useSelector(clientRemoteNoError);
    const [enableDelete, setEnableDelete] = useState<boolean>(false);
    const User = JSON.parse(localStorage.getItem('User') as string);
    const userRole = User.role;
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [call, setCall] = useState(false);
    const saveStatus = useSelector(selectClientSaveStatus);
    const profiles = useSelector(selectAllProfiles);
    const profilesStatus = useSelector(selectAllProfilesRetrieveStatus);

    useEffect(() =>
    {
        if (values?.email)
        {
            setEnableDelete(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function onChange(event: React.ChangeEvent<HTMLInputElement>): void
    {
        const { value, name } = event.target;
        setValues((current) => ({
            ...current,
            [name]: value,
        }));
        setFormErrors((current) => ({
            ...current,
            [name]: value ? undefined : [t('common.form-messages.required')],
        }));
    }

    function onDisableOfficerChange(_event: unknown, value: boolean): void
    {
        setValues((current) => ({
            ...current,
            disableClient: value,
        }));
    }

    function onBlur(event: React.FocusEvent<HTMLInputElement>): void
    {
        const { value, name } = event.target;
        const trimmedValue = value.trim();

        setValues((current) => ({
            ...current,
            [name]: trimmedValue,
        }));

        setFormErrors((current) => ({
            ...current,
            [name]: trimmedValue
                ? undefined
                : [t('common.form-messages.required')],
        }));
    }

    function onAgencySelect(value: unknown): void
    {
        const agencyId = (value as string) ?? undefined;
        dispatch(getOfficerFilterOptions({ agencyId }));
        setValues((current) => ({
            ...current,
            agencyId,
        }));
        setFormErrors((current) => ({
            ...current,
            agencyId: value ? undefined : [t('common.form-messages.required')],
        }));
    }

    function onOfficerSelect(value: OfficersParams[]): void
    {
        setValues((current) => ({
            ...current,
            officers: value,
        }));
        setFormErrors((current) => ({
            ...current,
            officers: value ? undefined : [t('common.form-messages.required')],
        }));
    }

    function onChangeOfficer(value: (FilterOption | null)[]): void
    {
        const officerArray = value.map((officer) =>
        {
            return {
                value: officer?.value ?? '',
                name: officer?.name ?? '',
            };
        });
        setOfficers(value);
        onOfficerSelect(officerArray);
    }

    function onAutocompleteChange<T>(
        fieldName: keyof ClientFormValues,
        valueGetter: (item: T) => string
    ): (event: React.SyntheticEvent, value: T[]) => void
    {
        return (event: React.SyntheticEvent<Element, Event>, value: T[]) =>
        {
            setValues((current) => ({
                ...current,
                [fieldName]: value.map(valueGetter),
            }));

            setFormErrors((current) => ({
                ...current,
                [fieldName]: value?.length
                    ? undefined
                    : [t('common.form-messages.required')],
            }));
        };
    }

    function onAutocompleteBlur(
        fieldName: keyof ClientFormValues
    ): (event: React.FocusEvent<HTMLInputElement>) => void
    {
        if (fieldName === 'profiles' || fieldName === 'cellPhone')
        {
            return (event: React.FocusEvent<HTMLInputElement>) =>
            {
                setFormErrors((current) => ({
                    ...current,
                    [fieldName]: values[fieldName]?.length
                        ? undefined
                        : [t('common.form-messages.required')],
                }));
            };
        }
        else
        {
            return (event: React.FocusEvent<HTMLInputElement>) =>
            {
                setFormErrors((current) => ({
                    ...current,
                }));
            };
        }
    }

    function hasErrors(field: string): boolean
    {
        return !!formErrors[field];
    }

    function getErrorMessages(field: string): string
    {
        return formErrors[field]?.join('\n') ?? '';
    }

    useEffect(() =>
    {
        if (clientRemoteNoErrors?.statusCode === 409)
        {
            setFormErrors((current) => ({
                ...current,
                remoteCOMAccount: [`${clientRemoteNoErrors?.message}`],
            }));
        }
        else if (clientRemoteNoErrors?.statusCode === 406)
        {
            setFormErrors((current) => ({
                ...current,
                email: [`${clientRemoteNoErrors?.message}`],
            }));
        }
    }, [clientRemoteNoErrors]);

    function onSubmitHandler(event: React.FormEvent): void {
        event.preventDefault();
        const emailError = validateEmail(values.email);
        const cellPhoneError = values.cellPhone.length > 0
            ? undefined
            : [t('common.form-messages.required')];

        const newErrors = {
            ...formErrors,
            cellPhone: cellPhoneError,
            email: emailError ? [emailError] : undefined,
        };
        setFormErrors(newErrors);

        if (!Object.values(newErrors).find((error) => error)) {
            onSubmit(values);
        }
    }
    

    useEffect(() =>
    {
        setValues(initialValues ?? DEFAULT_VALUES);
        if (initialValues.officers.length)
        {
            const officerArray = initialValues.officers.map((officer) =>
            {
                return {
                    name: officer.firstName + ' ' + officer.lastName || '',
                    value: officer._id ?? '',
                };
            });
            setOfficers(officerArray);
            onOfficerSelect(officerArray);
        }
    }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>
    {
        const errors = Object.values(formErrors);

        setCanSubmit(errors.length !== 0 && !errors.find((error) => error));
    }, [formErrors]);

    useEffect(() =>
    {
        if (!agencies)
        {
            dispatch(getAgencyFilterOptions());
        }
        if (!profiles)
        {
            const agencyId = (values.agencyId as string) ?? undefined;
            dispatch(getOfficerFilterOptions({ agencyId }));
            dispatch(getAllProfiles());
        }
    }, [dispatch, agencies,profiles]);

    function isAgencyEnabled(): boolean
    {
        return (
            !(
                userIsInRole([Role.Officer, Role.OfficerSupervisor]) &&
                initialValues?.id
            ) || !initialValues?.id
        );
    }

    function onDeleteHandler(event?: React.FormEvent): void
    {
        event?.preventDefault();
        if (!Object.values(formErrors).find((error) => error))
            setDeleteOpen(!deleteOpen);
    }

    function handleCloseModal(): void
    {
        setDeleteOpen(false);
    }
    async function DeleteSubmit(data?: string): Promise<void>
    {
        if (data === values.firstName + ' ' + values.lastName)
        {
            setValues({
                ...values,
                deleted: true,
            });
            setCall(true);
        }
        if (saveStatus === RequestStatus.Success)
        {
            onDeleteHandler();
        }
    }

    const isSubmitDisabled = (): boolean =>
    {
        return (
            !canSubmit ||
            (values.disableClient &&
                userIsInRole([Role.Officer, Role.OfficerSupervisor]))
        );
    };

    useEffect(() =>
    {
        if (call === true)
        {
            onDelete(values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, call]);
    return agenciesStatus === RequestStatus.InProgress
    || profilesStatus === RequestStatus.InProgress ?(
            <CircularProgress />
        ) : (
            <form onSubmit={onSubmitHandler}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={5} direction={'column'}>
                            <Grid item>
                                <FormControl fullWidth>
                                    <TextField
                                        name="firstName"
                                        label={t('user.firstname')}
                                        autoComplete="off"
                                        value={values.firstName}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={hasErrors('firstName')}
                                        helperText={getErrorMessages('firstName')}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <TextField
                                        name="lastName"
                                        label={t('user.lastname')}
                                        autoComplete="off"
                                        value={values.lastName}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={hasErrors('lastName')}
                                        helperText={getErrorMessages('lastName')}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <TextField
                                        name="email"
                                        label={t('user.email')}
                                        type="email"
                                        autoComplete="off"
                                        value={values.email}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={hasErrors('email')}
                                        helperText={getErrorMessages('email')}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        multiple
                                        id="cellPhone"
                                        size="small"
                                        options={[]}
                                        freeSolo
                                        onChange={onAutocompleteChange<
                                        string | string[]
                                    >('cellPhone', (value) =>
                                        Array.isArray(value)
                                            ? value.join('')
                                            : value)}
                                        value={values.cellPhone}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('common.nouns.phone')}
                                                placeholder={t(
                                                    'common.nouns.phone'
                                                )}
                                                onBlur={onAutocompleteBlur(
                                                    'cellPhone'
                                                )}
                                                error={hasErrors('cellPhone')}
                                                helperText={getErrorMessages(
                                                    'cellPhone'
                                                )}
                                                type="tel"
                                                {...(!values.cellPhone.length && {
                                                    required: true,
                                                })}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={5} direction={'column'}>
                            <Grid item>
                                <FormControl fullWidth>
                                    <TextField
                                        name="remoteCOMAccount"
                                        label={t('form.remoteComNumber')}
                                        type="text"
                                        autoComplete="off"
                                        value={values.remoteCOMAccount}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={hasErrors('remoteCOMAccount')}
                                        helperText={getErrorMessages(
                                            'remoteCOMAccount'
                                        )}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                {isAgencyEnabled() ? (
                                    <DropdownSelector
                                        label={t('common.nouns.agency')}
                                        options={agencies ?? []}
                                        disabled={!isAgencyEnabled()}
                                        onChange={onAgencySelect}
                                        onBlur={(
                                            event: React.FocusEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                        ) => onAgencySelect(event.target.value)}
                                        error={hasErrors('agency')}
                                        helperText={getErrorMessages('agency')}
                                        value={values.agencyId}
                                        fullWidth
                                        required
                                    />
                                ) : (
                                    <TextField
                                        label={t('common.nouns.agency')}
                                        disabled={!isAgencyEnabled()}
                                        value={values.agencyInfo.agencyName}
                                        fullWidth
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    multiple
                                    id="officers"
                                    value={officers}
                                    size="small"
                                    options={officerFilters ?? []}
                                    filterSelectedOptions
                                    loading={
                                        officerFiltersStatus ===
                                    RequestStatus.InProgress
                                    }
                                    onChange={(_, data) => onChangeOfficer(data)}
                                    getOptionLabel={(option) => option?.name ?? ''}
                                    renderInput={(params) => (
                                        <TextField
                                            name="officers"
                                            {...params}
                                            label={t('data-type.filters.officer')}
                                            {...(!officers.length && {
                                                required: true,
                                            })}
                                            error={hasErrors('officers')}
                                            helperText={getErrorMessages(
                                                'officers'
                                            )}
                                        />
                                    )}
                                    renderOption={(props, option) =>
                                    {
                                        return (
                                            <li {...props} key={option?.value}>
                                                {option?.name}
                                            </li>
                                        );
                                    }}
                                    ChipProps={{ sx: chipStyle }}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        multiple
                                        id="profiles"
                                        size="small"
                                        options={profiles?.contents ?? []}
                                        getOptionLabel={(option) => option.name}
                                        onChange={onAutocompleteChange<Profile>(
                                            'profiles',
                                            ({ id }) => id
                                        )}
                                        value={
                                            profiles?.contents.filter(
                                                ({ id }) =>
                                                    values.profiles.includes(id)
                                            ) ?? []
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('common.nouns.profile')}
                                                placeholder={t(
                                                    'common.nouns.profile'
                                                )}
                                                onBlur={onAutocompleteBlur(
                                                    'profiles'
                                                )}
                                                error={hasErrors('profiles')}
                                                helperText={getErrorMessages(
                                                    'profiles'
                                                )}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    title={t('clients.delete-client')}
                    open={deleteOpen}
                    maxWidth="md"
                    fullWidth
                    onClose={handleCloseModal}
                    showCloseButton={true}
                >
                    <Box>
                        <ClientDetailsModel
                            data={values}
                            DeleteModal={onDeleteHandler}
                            DeleteSubmit={DeleteSubmit}
                            type={'Client'}
                        />
                    </Box>
                </Modal>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    padding="1em 0"
                    gap={1}
                >
                    <>
                        {userRole === 'Administrator' && enableDelete ? (
                            <Button
                                sx={{
                                    marginRight: 'auto',
                                }}
                                type="button"
                                variant="contained"
                                disabled={loading}
                                startIcon={<DeleteIcon />}
                                onClick={onDeleteHandler}
                            >
                                {t('common.actions.delete')}
                            </Button>
                        ) : null}
                    </>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            {userIsInRole([
                                Role.Administrator,
                                Role.AgencyAdministrator,
                                Role.Employee,
                            ]) ? (
                                    <Grid
                                        sx={{
                                            marginRight: 'auto',
                                            ml: 1,
                                            mt: 1,
                                        }}
                                    >
                                        {t('common.actions.block-officer')}
                                        <ToggleSwitch
                                            sx={{ ml: 1 }}
                                            checked={values.disableClient}
                                            onChange={onDisableOfficerChange}
                                        />
                                    </Grid>
                                ) : null}

                            <Button
                                type="button"
                                variant="outlined"
                                disabled={loading}
                                onClick={onCancel}
                            >
                                {t('common.actions.cancel')}
                            </Button>
                            {buttonLabel === 'create' ? (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitDisabled()}
                                >
                                    {t('common.actions.create')}
                                </Button>
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitDisabled()}
                                >
                                    {t('common.actions.save')}
                                </Button>
                            )}
                        </>
                    )}
                </Stack>
            </form>
        );
}
