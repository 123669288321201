import React from 'react';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export interface PrintedDocumentDetailProps {
    fileUrl: string
}

export function PrintedDocumentDetail({ fileUrl }: Readonly<PrintedDocumentDetailProps>)
    : JSX.Element
{
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column nowrap',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    overflow: 'auto',
                    height: '100vh',
                    background: theme => theme.palette.common.softGrey,
                    borderWidth: '1px',
                    borderColor: theme => theme.palette.common.lightGrey,
                    borderStyle: 'solid',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <iframe
                    title="pdf-iframe"
                    src={fileUrl}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                />
            </Box>
            <Box sx={{ padding: '0.5em', display: 'flex', justifyContent: 'center' }}>
                <Button
                    target="_blank"
                    href={fileUrl}
                    title="Open in a new tab"
                >
                    <OpenInNewIcon />
                </Button>
            </Box>
        </Box>
    );
}
