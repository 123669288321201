/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, FormGroup, Theme } from '@mui/material';
import TextField from '@mui/material/TextField';

import {
    selectFilters,
    changeFilters,
    selectAgencyFilterOptions,
    selectClientFilterOptions,
    selectDeviceFilterOptions,
    selectAgencyFilterOptionsStatus,
    selectClientFilterOptionsStatus,
    selectDeviceFilterOptionsStatus,
    getAgencyFilterOptions,
    getOfficerFilterOptions,
    getClientFilterOptions,
    getDeviceFilterOptions,
    changeAgencyFilter,
    changeOfficerFilter,
    changeClientFilter,
    selectOfficerFilterOptions,
    selectOfficerFilterOptionsStatus,
    resetPhotosData,
    resettextMMSsData,
    resetscreenCapturesData,
    changeGalleryFilters,
    selectGalleryFilters,
} from 'src/state/captures/capturesSlice';
import { FiltersBar } from 'src/components/filters-bar/FiltersBar';
import {
    DropdownSelector,
} from 'src/components/dropdown-selector/DropdownSelector';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';
import { DateFilterChange } from 'src/utils/dateUtils';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { useLocation } from 'react-router-dom';
import { PHOTOS, SCREENCAPTURES, TEXTMMS, SCREENSHOTSMETA } from 'src/utils/routes';
import { DropdownSearchSelector } from 'src/components/dropdown-selector/DropdownSearchSelector';
import { FilterOption } from 'src/models/common/filterOptionData.model';
import PolicyIcon from '@mui/icons-material/Policy';
import ToggleButton from 'src/components/toggle-switch/ToggleButton';
import { getDefaultGalleryView } from 'src/utils/common';
import { GalleryFilterType } from 'src/models/commentType.enum';

export function DataTypeFilters(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const officerFilters = useSelector(selectOfficerFilterOptions);
    const agencyFilters = useSelector(selectAgencyFilterOptions);
    const officerFiltersStatus = useSelector(selectOfficerFilterOptionsStatus);
    const agencyFiltersStatus = useSelector(selectAgencyFilterOptionsStatus);
    const filters = useSelector(selectFilters);
    const deviceFilters = useSelector(selectDeviceFilterOptions);
    const deviceFiltersStatus = useSelector(selectDeviceFilterOptionsStatus);
    const clientFilters = useSelector(selectClientFilterOptions);
    const clientFiltersStatus = useSelector(selectClientFilterOptionsStatus);
    const galleryFilters = useSelector(selectGalleryFilters);

    const [isDateFilterChanged, setIsDateFilterChanged] = useState(false);
    const [startDate, setStartDate] = useState<Date | null | undefined>();
    const [endDate, setEndDate] = useState<Date | null | undefined>();
    const location = useLocation();

    useEffect(() =>
    {
        if (!userIsInRole([Role.Officer]))
        {
            dispatch(getAgencyFilterOptions());
        }
        dispatch(getOfficerFilterOptions({ agencyId: filters.agency }));
        dispatch(getClientFilterOptions({
            agencyId: filters.agency,
            officerId: filters.officer,
            isDisableRecords: filters.isDisableRecords,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId: filters.agency,
            officerId: filters.officer,
            clientId: filters.client,
            isDisableRecords: filters.isDisableRecords,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onChangeSelectFilter(
        field: keyof CaptureFilters
    ): (option: FilterOption) => void
    {

        return option =>
        {
            dispatch(
                changeFilters({
                    [field]: option?.value ? option?.value : undefined,
                    [`${field}Name`]: option?.name ? option?.name : undefined,
                })
            );
        };
    }
    function onChangeAgencyFilter(agency: unknown): void
    {
        const agencyId = agency as string ?? undefined;

        dispatch(changeAgencyFilter(agencyId));
        dispatch(getOfficerFilterOptions({ agencyId }));
        dispatch(getClientFilterOptions({
            agencyId,
            isDisableRecords: filters.isDisableRecords,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId,
            isDisableRecords: filters.isDisableRecords,
        }));
    }
    function onChangeOfficerFilter(officer: unknown): void
    {
        const officerId = officer as string ?? undefined;
        dispatch(changeOfficerFilter(officerId));
        dispatch(getClientFilterOptions({
            agencyId: filters.agency,
            officerId,
            isDisableRecords: filters.isDisableRecords,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId: filters.agency,
            officerId,
            isDisableRecords: filters.isDisableRecords,
        }));
    }
    function onChangeClientFilter(client: unknown): void
    {
        const clientId = client as string ?? undefined;
        dispatch(changeClientFilter(clientId));
        dispatch(getDeviceFilterOptions({
            agencyId: filters.agency,
            officerId: filters.officer,
            clientId,
            isDisableRecords: filters.isDisableRecords,
        }));
    }

    const onChangeDateCheckedFilter = (): void =>
    {
        setIsDateFilterChanged(!isDateFilterChanged);
        const updatedFilters = { ...filters, isDateFilterChanged: true };
        dispatch(changeFilters(updatedFilters));
    };

    const onChangeDefaultFilter = (): void =>
    {
        setIsDateFilterChanged(!isDateFilterChanged);
        const updatedFilters = { ...filters, isDateFilterChanged: false };
        dispatch(changeFilters(updatedFilters));
    };

    const onChangeDateFilter = (
        field: keyof CaptureFilters,
        // eslint-disable-next-line
        newdate?: any
    ): void =>
    {
        const dateTime = DateFilterChange(field, newdate, startDate, endDate);
        setStartDate(dateTime.startDateTime);
        setEndDate(dateTime.endDateTime);
        dispatch(changeFilters({
            startDate: dateTime.startDateTime,
            endDate: dateTime.endDateTime,
        }));
    };
    // eslint-disable-next-line
    const onToggleChange = (event: any): void => {
        const updatedFilters = {
            ...filters,
            device: undefined,
            isDisableRecords: event.target.checked,
            alertwordsOnly: undefined,
        };
        dispatch(changeFilters(updatedFilters));
        dispatch(getClientFilterOptions({
            agencyId: filters.agency,
            officerId: filters.officer,
            isDisableRecords: event.target.checked,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId: filters.agency,
            officerId: filters.officer,
            clientId: filters.client,
            isDisableRecords: event.target.checked,
        }));
    };

    // eslint-disable-next-line
    const onTogglegalleryChange = (event: ChangeEvent<HTMLInputElement>, key:string): void =>
    {
        if(filters.isGridView !== event.target.checked && key === GalleryFilterType.GRID_VIEW)
        {
            dispatch(resetPhotosData());
            dispatch(resettextMMSsData());
            dispatch(resetscreenCapturesData());
            const updatedFilters = {
                ...filters,
                isGridView: event.target.checked,
                alertwordsOnly: undefined,
            };
            dispatch(changeFilters(updatedFilters));
        }

        if(key === GalleryFilterType.GRID_VIEW)
        {
            dispatch(changeGalleryFilters({
                gridView: event.target.checked,
                slideshow: false,
            }));
        }
        else
        {
            dispatch(changeGalleryFilters({
                slideshow: event.target.checked,
            }));
        }
    };

    return (
        <FiltersBar>
            {
                !userIsInRole([Role.Officer]) && (
                    <DropdownSelector
                        options={agencyFilters ?? []}
                        label={t('data-type.filters.agency')}
                        labelId="agency-filter-label"
                        value={filters.agency ?? ''}
                        onChange={onChangeAgencyFilter}
                        sx={{ width: '16rem' }}
                        clearable={true}
                        isLoading={agencyFiltersStatus === RequestStatus.InProgress}
                        inputProps={{
                            sx: {
                                backgroundColor: (theme: Theme) => theme.palette.common.white,
                            },
                        }}
                    />
                )
            }
            {
                !userIsInRole([Role.Officer]) && (
                    <DropdownSelector
                        options={officerFilters ?? []}
                        label={t('data-type.filters.officer')}
                        labelId="officer-filter-label"
                        value={filters.officer ?? ''}
                        onChange={onChangeOfficerFilter}
                        sx={{ width: '16rem' }}
                        clearable={true}
                        isLoading={officerFiltersStatus === RequestStatus.InProgress}
                        inputProps={{
                            sx: {
                                backgroundColor: (theme: Theme) => theme.palette.common.white,
                            },
                        }}
                    />
                )
            }

            <DropdownSelector
                options={clientFilters ?? []}
                label={t('data-type.filters.client')}
                labelId="officer-filter-label"
                value={filters.client ?? ''}
                onChange={onChangeClientFilter}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={clientFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
            <DropdownSearchSelector
                options={deviceFilters ?? []}
                loading={deviceFiltersStatus === RequestStatus.InProgress }
                onValueChange={onChangeSelectFilter('device')}
                value={ filters.device && filters.deviceName ? {
                    value: filters.device,
                    name: filters.deviceName,
                }: null}
                label={t('data-type.filters.device')}
                labelId={t('data-type.filters.device')}
                searchIcon={<PolicyIcon/>}
                placeholder={'Search device'}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label={t('data-type.filters.start-date')}
                    value={
                        filters.startDate
                            ? (new Date(filters.startDate))
                            : null
                    }
                    onChange={(newValue) =>
                        onChangeDateFilter('startDate', newValue)
                    }
                    renderInput={(params) => (
                        <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            sx={{
                                backgroundColor: (theme: Theme) =>
                                    theme.palette.common.white,
                                borderRadius: 1,
                            }}
                            {...params}
                        />
                    )}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label={t('data-type.filters.end-date')}
                    value={
                        filters.endDate
                            ? (new Date(filters.endDate))
                            : null
                    }
                    onChange={(newValue) =>
                        onChangeDateFilter('endDate', newValue)
                    }
                    renderInput={(params) => (
                        <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            sx={{
                                backgroundColor: (theme: Theme) =>
                                    theme.palette.common.white,
                                borderRadius: 1,
                            }}
                            {...params}

                        />
                    )}
                />
            </LocalizationProvider>
            <FormGroup row style={{ marginTop: '-10px' }}>

                <FormControlLabel
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                    control={(
                        <Checkbox

                            sx={{
                                fontSize: '1rem',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}

                            checked={((filters.isDateFilterChanged) &&
                                (location.pathname
                                    !== SCREENSHOTSMETA))}
                            disabled={(filters.isDateFilterChanged)
                                ||
                                location.pathname === SCREENSHOTSMETA}


                            onChange={onChangeDateCheckedFilter}
                        />
                    )}
                    label={(
                        <span style={{ color: '#000000' }}>
                            {t('data-type.table.captured-date')}
                        </span>
                    )}
                />
                <FormControlLabel
                    sx={{ display: 'inline-flex', alignItems: 'center', pr: 8 }}
                    control={(
                        <Checkbox

                            sx={{
                                fontSize: '1rem',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            checked={!filters.isDateFilterChanged ||
                                location.pathname === SCREENSHOTSMETA}
                            disabled={!filters.isDateFilterChanged}


                            onChange={onChangeDefaultFilter}
                        />
                    )}

                    label={(
                        <span style={{ color: '#000000' }}>
                            {t('data-type.table.received-date')}
                        </span>
                    )}
                />
                <FormControlLabel
                    control={(
                        <ToggleSwitch
                            sx={{
                                m: 2,
                                '& .MuiSwitch-track': {
                                    backgroundColor: 'darkgray',
                                },
                            }}
                            checked={filters.isDisableRecords}
                            onClick={(event) =>
                            {
                                onToggleChange(event);
                            }}
                        />
                    )}
                    name="activated"
                    label={t('common.actions.disabled-device')}
                />
                {location.pathname === TEXTMMS ||
                    location.pathname === PHOTOS ||
                    location.pathname === SCREENCAPTURES ?
                    (
                        <>
                            {getDefaultGalleryView(
                                galleryFilters.gridView,
                                galleryFilters.slideshow
                            ).map((button)=>
                                (
                                    <ToggleButton
                                        key={button.label}
                                        label={button.label}
                                        keyOfFilters={button.keyOfFilters}
                                        checked={button.checked}
                                        onChange={onTogglegalleryChange}
                                    />
                                ))}
                        </>
                    ) : null}

            </FormGroup>
        </FiltersBar>
    );
}
