import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, FormControl, Grid, IconButton, Snackbar, SnackbarProps, Stack, TextField } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import TableDownloadBar from 'src/components/table-download-bar/TableDownloadBar';
import {
    getDevicesCacheDetails,
    selectDeleteDeviceCacheStatus,
    selectDeviceCacheDetails,
    selectDeviceCacheDetailsStatus,
    setDeviceCacheSearchValue,
    selectDeviceCacheSearchValue,
    changeDeviceCacheFilters,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { useTranslation } from 'react-i18next';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { DeviceCacheDetails } from 'src/models/administration/deviceCacheDetailsData.model';
import { DeviceCacheModal } from './DeviceCacheModal';
import { changeTableDataType } from 'src/state/captures/capturesSlice';
import { DataType } from 'src/models/dataType.enum';
import { RefreshTable } from '../refresh-table/RefreshTable';
import debounceSearch from 'src/utils/common';
import { DEBOUNCE_DELAY_TIME } from 'src/utils/environment';

export function DeviceCacheManagement(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const deviceCacheData = useSelector(selectDeviceCacheDetails);
    const status = useSelector(selectDeviceCacheDetailsStatus);
    const deleteStatus = useSelector(selectDeleteDeviceCacheStatus);
    const searchValue = useSelector(selectDeviceCacheSearchValue)
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [deviceCacheDetails, setDeviceCacheDetails] = useState<DeviceCacheDetails>();
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [searchInput, setSearchInput] = useState(searchValue);

    function onPageChange(pageNumber: number): void
    {
        dispatch(getDevicesCacheDetails({ pageNumber, entriesPerPage, searchValue }));
    }

    useEffect(() =>
    {
        dispatch(getDevicesCacheDetails({ pageNumber: 1, entriesPerPage, searchValue }));
        dispatch(changeTableDataType(DataType.DEVICE_CACHE));
    }, [dispatch, entriesPerPage, searchValue]);

    useEffect(() =>
        {
            let message = '';
    
            if (deleteStatus === RequestStatus.Success)
            {
                message = t('common.messages.cache-deletion-successfully');
            }
            else if (deleteStatus === RequestStatus.Failure)
            {
                message = t('common.messages.something-wrong');
            }
            if (message)
            {
                setNotificationState({ open: true, message });
            }
        }, [deleteStatus, setNotificationState, t, dispatch]);

    function onCloseNotification(): void
        {
            setNotificationState((current) => ({
                ...current,
                open: false,
            }));
        }
    
    function onModalClose(): void
    {
        setDeviceCacheDetails(undefined);
        if(deleteStatus !== RequestStatus.NotStarted)
        {
            dispatch(getDevicesCacheDetails({ pageNumber: 1, entriesPerPage, searchValue }));
        }
    }

    const debouncedSearchFilter = useCallback(
        debounceSearch((value: string): void => {
            dispatch(setDeviceCacheSearchValue(value));
        }, DEBOUNCE_DELAY_TIME),
        [dispatch]
    );

    function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const value = event.target.value;
        setSearchInput(value);
        debouncedSearchFilter(value);
        dispatch(
            changeDeviceCacheFilters({
                searchValue: value,
            })
        );
    }

    const getDetailsColumn = (device: DeviceCacheDetails): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => setDeviceCacheDetails(device)}>
            <InfoOutlinedIcon />
        </IconButton>
    );

    const columns: ColumnData<DeviceCacheDetails>[] = [
        {
            label: t('common.nouns.device'),
            size: 1,
            value: (data: DeviceCacheDetails) => data.deviceId,
        },
        {
            label: t('data-type.details.device-alias'),
            size: 2,
            value: (data: DeviceCacheDetails) => data.deviceAlias,
        },
        {
            label: t('common.nouns.agency'),
            size: 2,
            value: (data: DeviceCacheDetails) => data.agency,
        },
        {
            label: t('data-type.filters.client'),
            size: 2,
            value: (data: DeviceCacheDetails) => data.client,
        },
        {
            label: t('data-type.details.total-unsynced-counts'),
            size: 2,
            value: (data: DeviceCacheDetails) => data.totalUnsyncedCount,
        },
        {
            label: t('data-type.table.details'),
            size: 1,
            align: 'center' as const,
            format: (data: DeviceCacheDetails) => getDetailsColumn(data),
        },
    ];

    return (
        <>
            <Box>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        padding={'1em 0'}
                    >
                        <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            <TableDownloadBar />
                            </Grid>
                    <Box>
                        <Grid
                            item
                            md={3}
                            sm={12}
                            xs={12}
                            display={'flex'}
                            justifyContent={'flex-end'}
                            gap={5}
                        >
                            <FormControl>
                                <TextField
                                    name="search"
                                        label={ t('data-type.table.search')}
                                    value={searchInput}
                                        autoComplete="on"
                                    onChange={handleSearchChange}
                                    />
                                </FormControl>
                            <RefreshTable />
                            </Grid>
                        </Box>
                    </Stack>
                    <Grid>
                        <DataTable<DeviceCacheDetails>
                            isLoading={status === RequestStatus.InProgress}
                            columns={columns}
                            onPageChange={onPageChange}
                            entriesPerPage={entriesPerPage}
                            onChangeEntriesPerPage={setEntriesPerPage}
                            {...deviceCacheData}
                        />
                    </Grid>
            </Box>
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={deleteStatus === RequestStatus.Failure ? 'error' : 'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
            {
                deviceCacheDetails
                    && (
                        <DeviceCacheModal
                            open={!!deviceCacheDetails}
                            deviceCacheData={deviceCacheDetails}
                            onClose={onModalClose}
                        />
                    )
            }
        </>
    );
}
