import { Chip } from '@mui/material';
import React from 'react';
import { ChipColor, ChipSize } from 'src/models/chip.enum';
import { LogonType } from 'src/models/logonType.enum';
export interface CellChipProps{
    logonType: LogonType;
    size?: ChipSize;
}
export function LogOnEventsCellChip({ logonType, size }:Readonly<CellChipProps>):React.ReactElement
{
    return (
        <Chip
            label={logonType}
            color={[LogonType.LOCK, LogonType.LOGOUT].includes(logonType)
                ? ChipColor.Error : ChipColor.Success}
            size={size ?? ChipSize.Small}
        />
    );
}
