import React, { useRef, useState } from 'react';
import GridView from 'src/components/grid-image-view';
import { PhotoData } from 'src/models/captures/photoData.model';
import {
    getAllToExportDatas,
    getPhotos,
    getPhotosJPEG,
    resetAlertsFilterData,
    resetPhotosData,
    selectFilters,
    selectGalleryFilters,
    selectPhotos,
    selectViewDataIdStatus,
    selectViewDataIds,
    selectViewDataJPEG,
    selectViewDataJPEGStatus,
} from 'src/state/captures/capturesSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataType } from 'src/models/dataType.enum';
import { Button, CircularProgress, Grid } from '@mui/material';
import { downloadImages } from 'src/utils/common';
import {
    TableExportFormat,
    usePDFExport,
    usePDFExportSetter,
    useTableExporter,
} from 'src/components/table-exporter/TableExporterProvider';
import DownloadIcon from '@mui/icons-material/Download';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { PDFExport } from '../common-export-form/PDFExport';
import { JPEGExport } from '../common-export-form/JPEGExport';
import { GalleryViewHeader } from 'src/components/grid-image-view/GalleryViewHeader';
export const SlideshowButtonStyle ={
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
};
export default function PhotosAlertsGrid(
    { showDetails } : { readonly showDetails: (photoData: PhotoData) => void }
): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const photos = useSelector(selectPhotos);
    const filters = useSelector(selectFilters);

    const pdfExportSetter = usePDFExportSetter();
    const pdfExporter = usePDFExport();
    const { setExportFormat } = useTableExporter();
    const [selectedImages, setSelectedImages] = React.useState<Array<PhotoData>>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [alltoExport,setAlltoExport]=useState(false);
    const [pdfType, setPdfType] = React.useState('');
    const viewDataIds = useSelector(selectViewDataIds);
    const viewDataIdStatus = useSelector(selectViewDataIdStatus);
    const viewDataJPEG = useSelector(selectViewDataJPEG);
    const ViewDataJPEGstatus = useSelector(selectViewDataJPEGStatus);
    const galleryFilters = useSelector(selectGalleryFilters);

    React.useEffect(() =>
    {
        dispatch(resetAlertsFilterData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSetSelectedImages = (
        photoData: PhotoData,
        checked: boolean
    ): void =>
    {
        if (photoData)
        {
            if (checked)
            {
                setSelectedImages((prevSelectedImages: PhotoData[]) => [
                    ...prevSelectedImages,
                    photoData,
                ]);
                setSelectedFileIds((prevSelectedFileIds: string[]) => [
                    ...prevSelectedFileIds,
                    photoData.fileId,
                ]);
            }
            else
            {
                setSelectedImages((prevSelectedImages: PhotoData[]) =>
                    prevSelectedImages.filter(
                        (selectedImage) => selectedImage.fileId !== photoData.fileId
                    ));
                setSelectedFileIds((prevSelectedFileIds: string[]) =>
                    prevSelectedFileIds.filter(
                        (fileId) => fileId !== photoData.fileId
                    ));
            }
        }
    };

    async function handlerAllPDFExport(): Promise<void>
    {
        setFormatAll('PDF');
        dispatch(getAllToExportDatas({
            ...filters,
            pageNumber: 1,
            entriesPerPage: 10,
            exportSkipId: 0,
            exportlimit:photos?.numberOfEntries,
            pdfExport: true,
            dataType:DataType.PHOTOS,
        }));
        setAlltoExport(true)
    }

    const handleALLJPEGExport = (): void =>
    {
        setIsLoading(true);
        dispatch(getPhotosJPEG({
            ...filters,
        }));
    };

    const handleSelectPDF = (): void =>
    {
        if (selectedImages.length > 0)
        {
            pdfExportSetter({
                type: DataType.PHOTOS,
                recordIds: selectedImages.map((item) => item.id),
            });
            setPdfType('selected');
        }
    };

    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        if (tableExportFormat === TableExportFormat.PDF && pdfExporter)
        {
            await TableExporter.asPDF(pdfExporter);
        }
        else
        {
            setExportFormat(tableExportFormat);
        }
        setFormat(undefined);
    }

    React.useEffect(() =>
    {
        if (pdfType)
        {
            handlerPDFExport(TableExportFormat.PDF);
        }
        setPdfType('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfType]);

    const prevFilters = useRef(filters);

    React.useEffect(() =>
    {
        if(filters.isGridView === true)
        {
            const filtersChanged = checkFilterChange(filters, prevFilters.current);

            if(!filtersChanged)
            {
                dispatch(resetPhotosData());
            }

            getPhotos({
                pageNumber: 1,
                entriesPerPage: 10,
                ...filters,
            });
            prevFilters.current = filters;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <>
            <Grid container mb="1rem" mt="rem">
                <Grid item md={6} xs={12}>
                    <PDFExport
                        selectedValue={handlerAllPDFExport}
                        ids={viewDataIds}
                        idStatus={viewDataIdStatus}
                        data={DataType.PHOTOS}
                        formatAll={formatAll}
                        setFormatAll={setFormatAll}
                        alltoExport ={alltoExport}
                    />
                    <JPEGExport
                        selectedValue={handleALLJPEGExport}
                        ids={viewDataJPEG}
                        idStatus={ViewDataJPEGstatus}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                    {selectedImages.length > 0 ? (
                        <>
                            <Button
                                variant="text"
                                startIcon={
                                    TableExporter.loading &&
                                        format === TableExportFormat.PDF ? (
                                            <CircularProgress size="1rem" />
                                        ) : (
                                            <DownloadIcon />
                                        )
                                }
                                onClick={(e) =>
                                {
                                    handleSelectPDF();
                                }}
                            >
                                {t('common.button.selected-to-pdf')}
                            </Button>
                            <Button
                                variant="text"
                                startIcon={
                                    selectLoading ? (
                                        <CircularProgress size="1rem" />
                                    ) : (
                                        <DownloadIcon />
                                    )
                                }
                                onClick={() =>
                                {
                                    downloadImages(
                                        selectedImages,
                                        null,
                                        setSelectLoading
                                    );
                                }}
                            >
                                {t('common.button.selected-to-jpeg')}
                            </Button>
                        </>
                    ) : null}
                </Grid>
                <GalleryViewHeader
                    dataType={DataType.PHOTOS}
                    count={photos?.numberOfEntries ?? 0}
                />
            </Grid>
            <GridView
                images={photos?.contents?.map((photo, index) => ({
                    fileId: photo.fileId,
                    fileUrl: photo.fileUrl,
                    date: photo.capturedDate,
                    onClick: () => showDetails(photo),
                    onCheck: handleSetSelectedImages,
                    count: index + 1,
                    totalCount: photos?.numberOfEntries,
                    aiScanStatus: photo.aiScanStatus,
                    alertWords: photo.alertWords || [],
                    device: photo.device,
                    deviceId: photo.deviceId,
                    platform: photo.platform,
                    username: photo.username,
                    flag: photo.flag,
                    dataType: DataType.PHOTOS,
                    id: photo.id,
                    captureStartDate: photo.captureStartDate,
                    captureEndDate: photo.captureEndDate,
                    capturedDate: photo.capturedDate,
                    receivedDate: photo.receivedDate,
                    comments: photo.comments,
                })) || []}
                selectedFileIds={selectedFileIds}
                slideshow={galleryFilters.slideshow}
            />
        </>
    );
}
