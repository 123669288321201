import React from 'react';
import { useDispatch } from 'react-redux';
import { changeSessionFilters } from 'src/state/captures/capturesSlice';
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    TextField,
} from '@mui/material';
import debounceSearch from 'src/utils/common';
import { DEBOUNCE_DELAY_TIME } from 'src/utils/environment';

export function SessionSearchFilter(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function onChangeSessionFilter(value: string): void
    {
        dispatch(
            changeSessionFilters({
                searchValue: value,
            })
        );
    }
    const debouncedValue = debounceSearch(onChangeSessionFilter, DEBOUNCE_DELAY_TIME);
    return (
        <FormControl fullWidth>
            <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                autoComplete="off"
                onChange={event => debouncedValue(event.target.value)}
            />
        </FormControl>
    );
}
