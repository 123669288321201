import { DomainList, WebsiteData } from 'src/models/captures/websiteData.model';
import { readAPICaptureData, APICaptureData } from './captures';

export interface APIWebsitesResponse extends APICaptureData {
    createdAt: string,
    clientCreatedAt: string,
    url: string;
    userAgent: string;
    title: string;
    alertWords: string[];
}

export function readAPIWebsitesResponse(
    data: APIWebsitesResponse
): WebsiteData
{
    const {
        url,
        userAgent,
        title,
        alertWords,
        ...captureData
    } = data;

    return {
        url,
        userAgent,
        title,
        alertWords,
        ...readAPICaptureData(captureData),
    };
}
export function readAPIDomains(
    domainList: DomainList[]
): DomainList[]
{
    return domainList.map((data)=>
    {

        const {
            domain,
            total,
        } = data;

        return {
            domain,
            total,
        };
    });
}
