import { DataType } from './dataType.enum';

enum ReportType {
    REPORT = 'Report'
}

export const CommentType = { ...ReportType, ...DataType };
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CommentType = ReportType | DataType;

export enum GalleryFilterType{
    GRID_VIEW = 'gridView',
    SLIDESHOW = 'slideshow',
}
export enum GalleryFilterLabel{
    GRID_VIEW = 'Gallery View',
    SLIDESHOW = 'Gallery Slider',
}