/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable indent */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'src/templates/header/Header';
import {
    Alert,
    Autocomplete,
    CircularProgress,
    Box,
    Button,
    Grid,
    IconButton,
    Snackbar,
    SnackbarProps,
    TextField,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { useNavigate, useParams } from 'react-router-dom';
import {
    addInstructions,
    deletePlatformInstructions,
    getPlatformTypeInstruction,
    getTypeInstruction,
    resetInstructionData,
    selectInstructionCreateStatus,
    selectInstructionData,
    selectInstructionFileId,
    selectInstructionPlatFormType,
    selectuploadImageStatus,
    setAdminSettingsMenu,
    updateInstructions,
    uploadInstructionImage,
} from 'src/state/administration/administrationSlice';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
    DropdownOption,
    DropdownSelector
} from 'src/components/dropdown-selector/DropdownSelector';
import { Platform } from 'src/models/platforms.enum';
import { ADMIN_SETTINGS } from 'src/utils/routes';
import { AdminSettings } from 'src/models/adminSettings.enum';
import CancelIcon from '@mui/icons-material/Cancel';

interface Instruction {
    platform: Platform;
    type?: string;
    imageUrl?: string;
    contentType?: string;
    instructions: string[];
    fileId?: string;
    type_id?: string;
    _id?: string;
    id?: string;
    file: File | null; // Allow file to be either File or null
}
interface InstructionDetails {
    platform: Platform;
    type: string;
}
export function InstructionAdd(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [disableOnSubmit, setDisableOnSubmit] = useState<boolean>(false);
    const selectedInstructionData = useSelector(selectInstructionData);
    const instructionUpdateStatus = useSelector(selectInstructionCreateStatus);
    const instructionTypeValue = useSelector(selectInstructionPlatFormType);
    const imageId = useSelector(selectInstructionFileId);
    const imageUploadStatus = useSelector(selectuploadImageStatus);
    const instructionCreationStatus = useSelector(selectInstructionCreateStatus)
    const [deleteIds, setDeleteIds] = useState<string[] | undefined>(undefined);
    const [instructionMainDetails, setInstructionMainDetails] = useState<InstructionDetails>({
        platform: '' as Platform,
        type: '',
    });
    const [uploadImageIndexValue, setUploadImageIndexValue] = useState<number>();
    const [instructionValue, setInstructionValue] = useState<Instruction[]>([{
        platform: instructionMainDetails.platform,
        instructions: [' '],
        file: null,
        type: instructionMainDetails.type,
    }]);
    const [instructionValidate, setInstructionValidate] = useState<boolean>(false)
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() =>
{
        if (id)
{
            setIsLoading(true);
            dispatch(getTypeInstruction(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // eslint-disable-next-line
    function handleImageChange(selectorFiles: FileList | null, parentIndex: any): void {

        const formData = new FormData();
        if (selectorFiles && selectorFiles.length > 0) {
            const file = selectorFiles.item(0);
            setInstructionValue(prevInstructions => {
                const updatedInstructions = [...prevInstructions];
                updatedInstructions[parentIndex] = {
                    ...updatedInstructions[parentIndex],
                    file: selectorFiles[0],
                    contentType: selectorFiles[0].type,
                    imageUrl: ""
                };
                return updatedInstructions;
            });
            formData.append('file', selectorFiles[0]);
            dispatch(uploadInstructionImage(formData));
            setUploadImageIndexValue(parentIndex);
        }
    }

    useEffect(() => {
        if (
            id &&
            selectedInstructionData &&
            selectedInstructionData.length > 0
        ) {
            setInstructionValue(selectedInstructionData);
            setInstructionMainDetails({
                platform: selectedInstructionData[0].platform,
                type: selectedInstructionData[0].type,
            });
            dispatch(
                getPlatformTypeInstruction({
                    platform: selectedInstructionData[0].platform as Platform,
                })
            );
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInstructionData]);

    useEffect(() => {
        if (
            imageUploadStatus === RequestStatus.Success &&
            imageId !== undefined &&
            uploadImageIndexValue !== undefined
        ) {
            setInstructionValue((prevInstructions) => {
                const updatedInstructions = [...prevInstructions];
                updatedInstructions[uploadImageIndexValue] = {
                    ...updatedInstructions[uploadImageIndexValue],
                    fileId: imageId.fileId,
                    imageUrl: imageId.imageUrl,
                    contentType: imageId.contentType
                };
                return updatedInstructions;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageId, uploadImageIndexValue]);

    const addInstruction = (childIndex: number, parentIndex: number): void => {
        setInstructionValue((prevInstructionValue) => {
            const updatedInstructions = [...prevInstructionValue];

            const updatedParent = {
                ...updatedInstructions[parentIndex],
                instructions: [
                    ...updatedInstructions[parentIndex].instructions,
                    ' ',
                ],
            };
            updatedInstructions[parentIndex] = updatedParent;
            return updatedInstructions;
        });
    };

    const RemoveInstruction = (
        childIndex: number,
        parentIndex: number
    ): void => {
        setInstructionValue((prevInstructionValue) => {
            const updatedInstructions = prevInstructionValue.map(
                (parent, index) => {
                    if (index === parentIndex) {
                        const updatedInstructionsArray = [
                            ...parent.instructions,
                        ];
                        if (
                            childIndex >= 0 &&
                            childIndex < updatedInstructionsArray.length
                        ) {
                            updatedInstructionsArray.splice(childIndex, 1);
                        }
                        return {
                            ...parent,
                            instructions: updatedInstructionsArray,
                        };
                    }
                    return parent;
                }
            );
            return updatedInstructions;
        });
    };
    const RemoveMainInstruction = (
        parentIndex: number,
        deleteId: string | undefined
    ): void => {
        setInstructionValue((prevInstructionValue) => {
            // Filter out the parent at the specified index
            const updatedInstructions = prevInstructionValue.filter(
                (_, index) => index !== parentIndex
            );
            return updatedInstructions;
        });
        if (deleteId) {
            setDeleteIds((prevDeleteIds) => {
                const updatedDeleteIds = prevDeleteIds ? prevDeleteIds : [];
                return [...updatedDeleteIds, deleteId];
            });

        }

    };


    const addUploadFile = (): void => {
        setInstructionValue(prevValues => [
            ...prevValues,
            {
                instructions: [' '],
                file: null,
                platform: instructionMainDetails.platform,
                type: instructionMainDetails.type,
            },
        ]);
    };
    useEffect(() => {
        {
            dispatch(setAdminSettingsMenu(AdminSettings.Instruction));
            setNotificationState({ open: true, message: 'Created Successfully' });
            if (instructionUpdateStatus === RequestStatus.Success) {
                setTimeout(() => {
                    dispatch(resetInstructionData());
                    navigate(ADMIN_SETTINGS);
                }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructionUpdateStatus]);
    function getPlatformOptions(): DropdownOption[] {
        return Object.entries(Platform).map(([name, value]) => ({
            name,
            value,
        }));
    }
    function getPlatTypeOptions(): DropdownOption[] {
        if (instructionTypeValue) {
            return instructionTypeValue && instructionTypeValue.map((item: any) => {
                return {
                    name: item.type,
                    value: item._id,
                    divider: item?.instructionCreated && !id ? true : false,
                };
            });
        }
        else {
            return [];
        }
    }

    function onPlatformSelect(value: unknown): void {
        setInstructionMainDetails({
            type: instructionMainDetails.type,
            platform: value as Platform,
        });
        dispatch(
            getPlatformTypeInstruction({
                platform: value as Platform,
            })
        );
    }
    const handleSubmit = (): void => {
        const hasEmptyString = instructionValue.some(instruction =>
            instruction.instructions && instruction.instructions.includes(' ')) || instructionValue.some(instruction =>
                instruction.instructions && instruction.instructions.includes('') || instructionValue.some(instruction =>
                    instruction.instructions && instruction.instructions.length === 0));
        const hasFileId = instructionValue.every(instruction => instruction?.fileId !== undefined);
        if (hasEmptyString === true || hasFileId === false || instructionMainDetails.type == '' ||
            instructionMainDetails.platform == '' as Platform || instructionValue.length === 0) {
            setInstructionValidate(true);
        }
        else {
            setDisableOnSubmit(true)
            if (id) {
                const updatedValue = instructionValue.map((instruction) => {
                    return {
                        ...instruction,
                        type: instructionMainDetails.type,
                        platform: instructionMainDetails.platform,
                        type_id: id,
                    };
                });

                dispatch(updateInstructions(updatedValue));
            }
            else {
                const updatedValue = instructionValue.map((instruction, index) => {
                    return {
                        ...instruction,
                        platform: instructionMainDetails.platform,
                        type: instructionMainDetails.type,
                    };
                });
                dispatch(addInstructions(updatedValue));
            }
            if (deleteIds && deleteIds?.length > 0 && id) {
                dispatch(deletePlatformInstructions({
                    id: deleteIds,
                }));
            }
            setInstructionValidate(false);

        }
        setTimeout(() => {
            setInstructionValidate(false);
        }, 2000);
    };
    return (
        <>
            <Header />
            <Box m={4}>
                <AdministrationHeader
                    labels={['Instruction', 'Create']}
                    icon={<SettingsRoundedIcon fontSize="large" />}
                />
                <Grid container spacing={5}>
                    <Grid item sm={12} md={6}>
                        <DropdownSelector
                            name="platform"
                            label={t('data-type.table.platform')}
                            options={getPlatformOptions()}
                            value={instructionMainDetails.platform}
                            onChange={onPlatformSelect}
                            disabled={id ? true : false}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>

                        <DropdownSelector
                            name="platform"
                            label="Type"
                            options={getPlatTypeOptions()}
                            value={instructionMainDetails.type}
                            disabled={id ? true : false}
                            onChange={(event) =>
                                setInstructionMainDetails({
                                    platform: instructionMainDetails.platform,
                                    type: event as string,
                                })
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={11}>
                        <Typography
                            variant="h3"
                            sx={{
                                color: (theme) =>
                                    theme.palette.common.lightGrey,
                                fontSize: '1.25rem', // 20px
                            }}
                        >
                            Instruction Upload
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={1}>
                        <IconButton onClick={() => addUploadFile()}>
                            <AddCircleIcon />
                        </IconButton>
                        Add
                    </Grid>
                {(isLoading) ? (
                <Box sx={{ margin: '4rem calc((100% - 40px) / 2)' }}>
                <CircularProgress />
                </Box>
                ) : instructionValue?.length > 0 &&
                        instructionValue.map((item, parentKey) => (
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                key={parentKey}
                                style={{
                                    border: '2px solid #e0e0e0',
                                    padding: '10px',
                                    marginBottom: '20px',
                                    marginTop: '1%',
                                    marginLeft: '2%',
                                }}
                            >
                                <IconButton
                                    style={{ marginLeft: '97%' }}
                                    onClick={() =>
                                        RemoveMainInstruction(
                                            parentKey,
                                            item?._id
                                        )
                                    }
                                >
                                    <CancelIcon />
                                </IconButton>

                                <Grid item xs={12} md={3}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                    >
                                        Upload Image
                                        <input
                                            onChange={(e) =>
                                                handleImageChange(
                                                    e.target.files,
                                                    parentKey
                                                )
                                            }
                                            name="logo"
                                            id={`instruction-${parentKey}`}
                                            // value={instructionValue[parentKey].file.fileName}
                                            hidden
                                            accept="image/*"
                                            type="file"
                                        />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    {item.contentType && item?.contentType.startsWith('image/') && item.imageUrl ? (
                                        <img
                                            src={item.imageUrl}
                                            style={{
                                                maxHeight: 200,
                                                maxWidth: 200,
                                            }}
                                            alt="Thumb"
                                        />
                                    ) : item.imageUrl && item.contentType && item?.contentType.startsWith('video/') ? (
                                        <video
                                            src={item.imageUrl}
                                            style={{
                                                maxHeight: 200,
                                                maxWidth: 200,
                                            }}
                                            controls
                                        />
                                    ) :
                                        item.file ? <CircularProgress /> :
                                            null}
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <IconButton
                                        style={{ marginLeft: '75%' }}
                                        onClick={() =>
                                            addInstruction(1, parentKey)
                                        }
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    {item.instructions?.length > 0 &&
                                        item.instructions.map(
                                            (step, childKey) => {
                                                return (
                                                    <>
                                                        <TextField
                                                            style={{
                                                                marginTop:
                                                                    childKey > 0
                                                                        ? '10px'
                                                                        : '',
                                                                width: '80%',
                                                            }}
                                                            key={childKey}
                                                            id={`instruction-${parentKey}-${childKey}`}
                                                            value={
                                                                instructionValue[
                                                                    parentKey
                                                                ].instructions[
                                                                childKey
                                                                ]
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setInstructionValue(
                                                                    (
                                                                        prevInstructionValue
                                                                    ) => {
                                                                        const updatedInstructions =
                                                                            prevInstructionValue.map(
                                                                                (
                                                                                    parent,
                                                                                    parentIndex
                                                                                ) => {
                                                                                    if (
                                                                                        parentIndex ===
                                                                                        parentKey
                                                                                    ) {
                                                                                        const updatedInstructionsArray =
                                                                                            parent.instructions.map(
                                                                                                (
                                                                                                    instruction,
                                                                                                    childIndex
                                                                                                ) => {
                                                                                                    if (
                                                                                                        childIndex ===
                                                                                                        childKey
                                                                                                    ) {
                                                                                                        return event
                                                                                                            .target
                                                                                                            .value;
                                                                                                    }
                                                                                                    return instruction;
                                                                                                }
                                                                                            );

                                                                                        return {
                                                                                            ...parent,
                                                                                            instructions:
                                                                                                updatedInstructionsArray,
                                                                                        };
                                                                                    }
                                                                                    return parent;
                                                                                }
                                                                            );

                                                                        return updatedInstructions;
                                                                    }
                                                                );
                                                            }}
                                                            label="Instruction"
                                                            name="instruction"
                                                        />
                                                        <IconButton
                                                            onClick={() =>
                                                                RemoveInstruction(
                                                                    childKey,
                                                                    parentKey
                                                                )
                                                            }
                                                        >
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </>
                                                );
                                            }
                                        )}
                                </Grid>
                            </Grid>
                        ))}
                </Grid>

                <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button
                        sx={{
                            mr: 1,
                            backgroundColor: 'transparent',
                            color: (theme) => theme.palette.common.goldYellow,
                            '&.Mui-disabled': {
                                backgroundColor: 'inherit',
                                color: (theme) =>
                                    theme.palette.common.goldYellow,
                            },
                        }}
                        onClick={() => {
                            dispatch(setAdminSettingsMenu(AdminSettings.Instruction));
                            dispatch(resetInstructionData());
                            navigate(ADMIN_SETTINGS);
                        }}
                    >
                        {t('form.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={disableOnSubmit || imageUploadStatus === RequestStatus.InProgress}
                    >
                        {(
                            instructionCreationStatus === RequestStatus.InProgress
                        )
                            ? <CircularProgress size={24} color="inherit" /> : t('common.actions.save')
                        }
                    </Button>


                </Box>
            </Box>
            {instructionUpdateStatus === RequestStatus.Success
                && notificationState?.message ? (
                <Snackbar
                    open={notificationState?.open}
                >
                    <Alert severity={'success'}>
                        {notificationState?.message}
                    </Alert>
                </Snackbar>
            )
                : ''}
            {instructionValidate ? (
                <Snackbar
                    open={notificationState?.open}
                >
                    <Alert
                        severity={'error'}
                    >
                        {t('user.instruction-validation')}
                    </Alert>
                </Snackbar>
            ) : ''
            }
        </>
    );
}
