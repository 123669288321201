export enum ChipColor {
    Default = 'default',
    Primary = 'primary',
    Secondary = 'secondary',
    Error = 'error',
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
  }

export enum ChipSize {
    Small = 'small',
    Medium = 'medium',
  }
