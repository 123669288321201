/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { FilterOption } from 'src/models/common/filterOptionData.model';
import { InputAdornment } from '@mui/material';

export interface DropdownSearchSelectorProps{
    onValueChange:(value: FilterOption)=>void,
    options: FilterOption[],
    value: FilterOption | null,
    loading: boolean,
    label:string,
    labelId:string,
    placeholder:string,
    searchIcon:React.ReactElement,
}

export function DropdownSearchSelector({
    onValueChange,
    options,
    loading,
    value,
    label,
    labelId,
    placeholder,
    searchIcon,
}: Readonly<DropdownSearchSelectorProps>): JSX.Element
{

    const [open, setOpen] = React.useState(false);

    return (
        <Autocomplete
            sx={{
                width: 250,
                backgroundColor: (theme) => theme.palette.common.white,
            }}
            key={labelId}
            value={value}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(_, newValue) => onValueChange(newValue ?? { value: '', name: '' })}
            isOptionEqualToValue={(option, val) => option.value === val?.value}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                {searchIcon}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}
