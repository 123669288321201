import { Box, Grid, Checkbox, Tooltip, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import { Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SmsIcon from '@mui/icons-material/Sms';
import { timeFormat } from 'src/utils/common';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { Flag } from 'src/models/flag.enum';
import { Platform } from 'src/models/platforms.enum';
import { DataType } from 'src/models/dataType.enum';
import {
    getCommentsfilter,
    selectCommentsfilter,
    selectCommentsfilterStatus,
} from 'src/state/comments/commentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ImageSlider } from '../slider/ImageSlider';

export interface PhotoItem {
    id: string,
    date?: Date | string,
    fileId: string,
    fileUrl: string,
    count: number | undefined,
    totalCount: number | undefined,
    onClick: () => void,
    // eslint-disable-next-line
    onCheck: (image: any,checked:boolean) => void,
    aiScanStatus?: number,
    alertWords: string[],
    flag: Flag,
    device: string,
    username: string,
    platform: Platform,
    captureStartDate: string,
    captureEndDate: string,
    capturedDate: string,
    receivedDate: string,
    dataType: DataType,
    comments?: number
}
interface GridImageViewProps {
    images: Array<PhotoItem>,
    selectedFileIds: Array<string>,
    slideshow?: boolean,
}

export default function GridImageView({
    images = [],
    selectedFileIds,
    slideshow,
}: Readonly<GridImageViewProps>): React.ReactElement
{
    const { t } = useTranslation();
    const pageOffsetValue = 50;
    const dispatch = useDispatch();
    const comments = useSelector(selectCommentsfilter);
    const commentsStatus = useSelector(selectCommentsfilterStatus);
    const [startIndexLocation, setStartIndexLocation] = React.useState<number>(0);
    const [endIndexLocation, setEndIndexLocation] = React.useState<number>(100);
    const [showMoveTop, setShowMoveTop] = React.useState(false);
    const [showMoveBottom, setShowMoveBottom] = React.useState(true);
    // eslint-disable-next-line
    // eslint-disable-next-line
    let lastScrollTop = 0;
    const isProcessing = React.useRef(false);

    useEffect(() =>
    {
        if(!slideshow)
        {
            const handleScroll = (): void =>
            {
                if (isProcessing.current) return;
                const totalCount = images?.[0]?.totalCount ?? 1;
                const scrollTop = window.scrollY;
                const isScrollingUp = scrollTop < lastScrollTop;
                // eslint-disable-next-line
                lastScrollTop = scrollTop;
                const scrollHeight = document.documentElement.scrollHeight;
                const clientHeight = window.innerHeight;
                const isAtTop = scrollTop < 600;
                const isAtBottom = scrollTop + clientHeight > scrollHeight - 900;

                const centerScrollPosition = (scrollHeight - clientHeight) / 2;
                setShowMoveTop(scrollTop > pageOffsetValue);
                setShowMoveBottom(!isAtBottom);
                if (isScrollingUp && isAtTop && startIndexLocation !== 0)
                {
                    const newStartIndex = Math.max(0, startIndexLocation - pageOffsetValue);
                    const newEndIndex = Math.max(100, endIndexLocation - pageOffsetValue);
                    setStartIndexLocation(newStartIndex);
                    setEndIndexLocation(newEndIndex);
                    window.scrollTo({
                        top: centerScrollPosition + 600,
                        behavior: 'auto',
                    });
                }

                if (
                    isAtBottom &&
                        endIndexLocation < totalCount &&
                        endIndexLocation !== totalCount - 1
                )
                {
                    isProcessing.current = true;
                    setEndIndexLocation(endIndexLocation + pageOffsetValue);
                    setStartIndexLocation(startIndexLocation + pageOffsetValue);
                    window.scrollTo({
                        top: centerScrollPosition - 1000,
                        behavior: 'auto',
                    });
                    setTimeout(() =>
                    {
                        isProcessing.current = false;
                    }, 500);
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () =>
            {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [startIndexLocation, endIndexLocation, images]);

    useEffect(() =>
    {
        const setContainerHeight = (): void =>
        {
            const parentElement = document.documentElement.parentElement;
            const parentHeight = parentElement?.clientHeight;

            if (parentHeight)
            {
                document.documentElement.style.height = `${
                    parentHeight * 0.87
                }px`;
            }
        };

        setContainerHeight();
        window.addEventListener('resize', setContainerHeight);

        return () =>
        {
            window.removeEventListener('resize', setContainerHeight);
        };
    }, []);

    const [commentsFetched, setCommentsFetched] = React.useState<{ [key: string]: boolean }>({});
    // eslint-disable-next-line
    const tooltipContent = (image: PhotoItem): any => {
        if (commentsStatus !== RequestStatus.Success)
        {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size="1.5rem" color="secondary" />
                </Box>
            );
        }
        else if (comments?.length)
        {
            // eslint-disable-next-line
            return comments.map((data: any, commentIndex, commentsArray) => (
                <div
                    style={{
                        marginBottom: data.seemore === true ? '10px' : '0px',
                    }}
                    key={`row-${commentIndex + 1}`}
                >
                    <div
                        key={`row-${commentIndex + 1}`}
                        style={{
                            whiteSpace: 'pre-line',
                            borderBottom:
                                commentIndex === commentsArray.length - 1
                                    ? 'none'
                                    : '0.5px outset',
                            marginTop:
                                commentIndex === 0 && commentsArray.length > 1
                                    ? '0'
                                    : '3px',
                            marginBottom:
                                commentIndex === commentsArray.length - 1
                                    && commentsArray.length > 1
                                    ? '0'
                                    : '3px',
                        }}
                    >
                        <div style={{ fontWeight: 'bold' }}>
                            {data.message}
                        </div>
                        <small>
                            {data?.createdBy
                            + ' | ' + data.createdByAgency
                            + ' ( ' + new Date(data?.createdAt).toLocaleString()
                            + ' )'}
                        </small>
                    </div>
                    {data.seemore === true && (
                        <div
                            onClick={image.onClick}
                            onKeyDown={image.onClick}
                            style={{
                                color: 'lightblue',
                                cursor: 'pointer',
                                float: 'right',
                                marginTop: '-10px',
                            }}
                        >
                            {t('common.notification.see-more')}
                        </div>
                    )}
                </div>
            ));
        }
        else
        {
            return t('common.nouns.no-comment');
        }
    };

    return slideshow ? (
        <Box
            sx={{
                marginTop: 10,
                marginBottom: -2,
            }}
        >
            <ImageSlider images={images}/>
        </Box>
    ):(
        <Grid
            container
            rowSpacing={{ xs: 0 }}
            columnSpacing={{ xs: 0 }}
            columns={{ xs: 1, sm: 2, md: 5 }}
        >
            <>
                {images?.length > 0 &&
                    images
                        .filter(
                            (item, idx) =>
                                idx >= startIndexLocation &&
                                idx < endIndexLocation
                        )
                        .map((image, idx) =>
                        {
                            return (
                                <Grid
                                    item
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    key={image.fileId}
                                    sx={{
                                        boxShadow: 2,
                                        padding: '0.5rem',
                                        aspectRatio: '1:1',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            with: '100%',
                                            padding: '0.5rem',
                                            borderStyle: 'solid',
                                            borderColor: 'lightgray',
                                            boxShadow:
                                        '0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25)',
                                        }}
                                    >
                                        <Grid
                                            container
                                            sx={{
                                                justifyContent: 'space-between',
                                                marginTop: '0.5rem',
                                            }}
                                        >

                                            <Grid item>
                                                <>
                                                    {image.date && timeFormat(image.date)}
                                                </>
                                            </Grid>


                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <span>
                                                    {`${image.count} / ${image.totalCount}`}
                                                </span>
                                            </Grid>

                                        </Grid>
                                        <Box
                                            onClick={image.onClick}
                                            sx={{
                                                dropShadow: 2,
                                                width: '100%',
                                                aspectRatio: '0.9',
                                                position: 'relative',
                                                cursor: 'pointer',
                                                img: {
                                                    ':before': {
                                                        content: '" "',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundImage:
                                                    'url(/noimage.jpg)',
                                                        backgroundSize: 'cover',
                                                    },
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                },
                                            }}
                                        >
                                            <img
                                                key={image.id}
                                                src={image.fileUrl}
                                                alt={image.fileId}
                                            />
                                        </Box>
                                        <Grid
                                            container
                                            sx={{
                                                justifyContent: 'space-between',
                                                marginTop: '0.5rem',
                                            }}
                                        >
                                            <Grid item>
                                                <Checkbox
                                                    sx={{ padding: 0 }}
                                                    checked={
                                                        selectedFileIds?.includes(image.fileId)
                                                    }
                                                    onChange={
                                                        (e) => image.onCheck(
                                                            image,
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                sx={{ alignSelf: 'center', width: '10%' }}
                                            >
                                                {image.aiScanStatus === 2 ? (
                                                    <ScoutRed />
                                                ) : null}
                                            </Grid>
                                            <Grid item sx={{ position: 'relative', left: 0 }}>
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        left: 1,
                                                        opacity: 1,
                                                    }}
                                                >
                                                    <DataTypeFlagItem
                                                        getIds={(data) => [data.id]}
                                                        capturedData={image}
                                                        dataType={image.dataType}
                                                    />
                                                </span>
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                {image.comments && image.comments > 0 ? (
                                                    <Tooltip
                                                        onMouseOver={() =>
                                                        {
                                                            if (!commentsFetched[image.id])
                                                            {
                                                                dispatch(getCommentsfilter({
                                                                    contentId:
                                                                        image.id,
                                                                    commentType:
                                                                        DataType.SCREENSHOTS,
                                                                }));
                                                                setCommentsFetched(
                                                                    prevState => ({
                                                                        ...prevState,
                                                                        [image.id]: true,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        onMouseLeave={() =>
                                                        {
                                                            setCommentsFetched(
                                                                prevState => ({
                                                                    ...prevState,
                                                                    [image.id]: false,
                                                                })
                                                            );
                                                        }}
                                                        TransitionProps={{ timeout: 100 }}
                                                        title={tooltipContent(image)}
                                                        placement="right"
                                                        arrow
                                                    >
                                                        <SmsIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                                opacity: 0.5,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ) : null
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            sx={{
                                                justifyContent: 'space-between',
                                                marginTop: '0.5rem',
                                            }}
                                        >
                                            <Grid>
                                                <span>
                                                    {image.alertWords?.join(', ')}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            );
                        })}

            </>
            {showMoveTop && (
                <Fab
                    color="primary"
                    sx={{ position: 'fixed', bottom: showMoveBottom ? 80 : 16, right: 16 }}
                    onClick={() =>
                    {
                        setStartIndexLocation(0);
                        setEndIndexLocation(100);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            )}
            {showMoveBottom && (
                <Fab
                    color="primary"
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                    onClick={() =>
                    {
                        const imagesCount = images?.length ?? 0;
                        const endIndex = Math.ceil(imagesCount / pageOffsetValue) * pageOffsetValue;
                        const startIndex = endIndex > 100 ? endIndex - 100 : 0;
                        setStartIndexLocation(startIndex);
                        setEndIndexLocation(endIndex);
                        window?.scrollTo({
                            top: document.documentElement.scrollHeight,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <KeyboardArrowDownIcon />
                </Fab>
            )}
        </Grid>
    );
}
