import { Box, Grid, Checkbox, CircularProgress, Fab } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Flag } from 'src/models/flag.enum';
import { DataType } from 'src/models/dataType.enum';
import { Platform } from 'src/models/platforms.enum';
import SmsIcon from '@mui/icons-material/Sms';
import Tooltip from '@mui/material/Tooltip';
import { timeFormat } from 'src/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCommentsfilter,
    selectCommentsfilter,
    selectCommentsfilterStatus,
} from 'src/state/comments/commentsSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { useTranslation } from 'react-i18next';
import { screenshotFilterFlags } from 'src/state/captures/capturesSlice';
import { ImageSlider } from '../slider/ImageSlider';

export interface ImageItem
{
    id: string,
    date: Date,
    fileId: string,
    fileUrl: string,
    count: number | undefined,
    totalCount: number | undefined,
    onClick: () => void,
    onCheck: (image: ImageItem,checked:boolean) => void,
    aiScanStatus: number,
    alertWords: string[],
    flag : Flag,
    device: string,
    username: string,
    platform: Platform,
    captureStartDate: string,
    captureEndDate: string,
    capturedDate: string,
    receivedDate: string,
    comments?: number
}
interface GridImageModalViewProps
{
    images: Array<ImageItem>;
    alertWords: boolean;
    selectedFileIds?:Array<string>;
    slideshow?: boolean;
}

export default function GridImageModalView( {
    images=[],
    alertWords,
    selectedFileIds,
    slideshow,

}: Readonly<GridImageModalViewProps> ): React.ReactElement
{
    const { t } = useTranslation();
    const [showMoveTop, setShowMoveTop] = React.useState(false);
    const [showMoveBottom, setShowMoveBottom] = React.useState(true);
    const [startIndexLocation, setStartIndexLocation] = React.useState<number>(0);
    const [endIndexLocation, setEndIndexLocation] = React.useState<number>(50);
    const imageContainer = useRef<HTMLDivElement>(null);
    let lastScrollTop = 0;
    const pageOffsetValue = 25;
    const dispatch = useDispatch();
    const comments = useSelector(selectCommentsfilter);
    const commentsStatus = useSelector(selectCommentsfilterStatus);
    const filterFlagData = useSelector(screenshotFilterFlags);
    const prevFilterFlagData = useRef(filterFlagData);

    useEffect(() =>
    {
        if (prevFilterFlagData.current !== filterFlagData)
        {
            setStartIndexLocation(0);
            setEndIndexLocation(50);
            prevFilterFlagData.current = filterFlagData;
            const imageElement = imageContainer.current;
            if (imageElement) imageElement?.scrollTo({ top: 0, behavior: 'auto' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFlagData]);

    useEffect(() =>
        {
            setStartIndexLocation(0);
            setEndIndexLocation(50);
            const imageElement = imageContainer.current;
            if (imageElement) imageElement?.scrollTo({ top: 0, behavior: 'auto' });
        }, [alertWords]);

    useEffect(() =>
    {
        const handleScroll = (): void =>
        {
            const imageElement = imageContainer.current;
            if (imageElement)
            {
                const totalCount = images?.[0]?.totalCount ?? 1;
                const scrollTop = imageElement.scrollTop;
                const isScrollingUp = scrollTop < lastScrollTop;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                lastScrollTop = scrollTop;
                const scrollHeight = imageElement?.scrollHeight;
                const clientHeight = imageElement?.clientHeight;
                const isAtTop = scrollTop < 100;
                const isAtBottom = scrollTop + clientHeight > scrollHeight - 100;

                const centerScrollPosition = (scrollHeight - clientHeight) / 2;
                setShowMoveTop(scrollTop > 50);
                setShowMoveBottom(!isAtBottom);
                if (isScrollingUp && isAtTop && startIndexLocation !== 0)
                {
                    const newStartIndex = Math.max(0, startIndexLocation - pageOffsetValue);
                    const newEndIndex = Math.max(50, endIndexLocation - pageOffsetValue);
                    setStartIndexLocation(newStartIndex);
                    setEndIndexLocation(newEndIndex);
                    imageElement?.scrollTo({ top: centerScrollPosition + 500, behavior: 'auto' });
                }

                if (
                    isAtBottom &&
                    endIndexLocation < totalCount &&
                    endIndexLocation !== totalCount - 1
                )
                {
                    setEndIndexLocation(endIndexLocation + pageOffsetValue);
                    setStartIndexLocation(startIndexLocation + pageOffsetValue);
                    imageElement?.scrollTo({ top: centerScrollPosition - 400, behavior: 'auto' });
                }
            }
        };

        const container = imageContainer.current;
        container?.addEventListener('scroll', handleScroll);
        return () =>
        {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, [startIndexLocation, endIndexLocation, filterFlagData, alertWords]);

    useEffect(() =>
    {
        const setContainerHeight = () : void=>
        {
            if (imageContainer.current)
            {
                const parentHeight = imageContainer.current.parentElement?.clientHeight;
                if(parentHeight) imageContainer.current.style.height = `${parentHeight * 0.87}px`;
            }
        };

        setContainerHeight();
        window.addEventListener('resize', setContainerHeight);

        return () =>
        {
            window.removeEventListener('resize', setContainerHeight);
        };
    }, []);

    const [commentsFetched, setCommentsFetched] = React.useState<{ [key: string]: boolean }>({});
    // eslint-disable-next-line
    const tooltipContent = (image: ImageItem): any => {
        if (commentsStatus !== RequestStatus.Success)
        {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size="1.5rem" color="secondary" />
                </Box>
            );
        }
        else if (comments?.length)
        {
            // eslint-disable-next-line
            return comments.map((data: any, commentIndex, commentsArray) => (
                <div
                    style={{
                        marginBottom: data.seemore === true ? '10px' : '0px',
                    }}
                    key={`row-${commentIndex + 1}`}
                >
                    <div
                        key={`row-${commentIndex + 1}`}
                        style={{
                            whiteSpace: 'pre-line',
                            borderBottom:
                                commentIndex === commentsArray.length - 1
                                    ? 'none'
                                    : '0.5px outset',
                            marginTop:
                                commentIndex === 0 && commentsArray.length > 1
                                    ? '0'
                                    : '3px',
                            marginBottom:
                                commentIndex === commentsArray.length - 1 &&
                                commentsArray.length > 1
                                    ? '0'
                                    : '3px',
                        }}
                    >
                        <div style={{ fontWeight: 'bold' }}>
                            {data.message}
                        </div>
                        <small>
                            {data?.createdBy +
                                ' | ' +
                                data.createdByAgency +
                                ' ( ' +
                                new Date(data?.createdAt).toLocaleString() +
                                ' )'}
                        </small>
                    </div>
                    {data.seemore === true && (
                        <div
                            onClick={image.onClick}
                            onKeyDown={image.onClick}
                            style={{
                                color: 'lightblue',
                                cursor: 'pointer',
                                float: 'right',
                                marginTop: '-10px',
                            }}
                        >
                            {t('common.notification.see-more')}
                        </div>
                    )}
                </div>
            ));
        }
        else
        {
            return t('common.nouns.no-comment');
        }
    };

    return slideshow
        ? (<ImageSlider images={images}/>)
        : (
            <Grid
                container
                rowSpacing={{ xs: 0 }}
                columnSpacing={{ xs: 0 }}
                columns={{ xs: 1, sm: 2, md: 5 }}
                ref={imageContainer}
                sx={{
                    overflowY: 'scroll',
                }}
            >
                <>
                    {images?.length > 0 && images
                        .filter((item, idx) => idx >= startIndexLocation && idx < endIndexLocation)
                        .map((image) =>
                        {
                            return (
                                <Grid
                                    item
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    key={image.fileId}
                                    sx={{
                                        boxShadow: 2,
                                        padding: '0.5rem',
                                        aspectRatio: '1:1',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        id={image.id}
                                        sx={{
                                            with: '100%',
                                            padding: '0.5rem',
                                            borderStyle: 'solid',
                                            borderColor: 'lightgray',
                                            boxShadow: '0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25)',
                                        }}
                                    >
                                        <Grid
                                            container
                                            sx={{
                                                justifyContent: 'space-between',
                                                marginTop: '0.5rem',
                                            }}
                                        >
                                            <Grid item>
                                                {
                                                    <>
                                                        {image.date && timeFormat(image.date)}
                                                    </>
                                                }
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <span>
                                                    {`${image.count} / ${image.totalCount}`}
                                                </span>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            onClick={image.onClick}
                                            sx={{
                                                dropShadow: 2,
                                                width: '100%',
                                                aspectRatio: '0.9',
                                                position: 'relative',
                                                cursor: 'pointer',
                                                img: {
                                                    ':before': {
                                                        content: '" "',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundImage: 'url(/noimage.jpg)',
                                                        backgroundSize: 'cover',
                                                    },
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                },
                                            }}
                                        >

                                            <img
                                                src={image.fileUrl}
                                                alt={image.fileId}
                                            />
                                        </Box>
                                        <Grid
                                            container
                                            sx={{
                                                justifyContent: 'space-between',
                                                marginTop: '0.5rem',
                                            }}
                                        >
                                            <Grid item>
                                                <Checkbox
                                                    sx={{ padding: 0 }}
                                                    checked={
                                                        selectedFileIds?.includes(image.fileId)}
                                                    onChange={
                                                        (e) =>
                                                            image.onCheck(image, e.target.checked)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center', width: '10%' }}>
                                                {image.aiScanStatus === 2 ?
                                                    <ScoutRed /> : null}
                                            </Grid>
                                            <Grid item sx={{ position: 'relative', left: 0 }}>
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        left: 1,
                                                        opacity: 1,
                                                    }}
                                                >
                                                    <DataTypeFlagItem
                                                        getIds={(data) => [data.id]}
                                                        capturedData={image}
                                                        dataType={
                                                            DataType.SCREENSHOTS_META}
                                                    />
                                                </span>
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                {image.comments && image.comments > 0 ? (
                                                    <Tooltip
                                                        onMouseOver={() =>
                                                        {
                                                            if (!commentsFetched[image.id])
                                                            {
                                                                dispatch(getCommentsfilter(
                                                                    {
                                                                        contentId: image.id,
                                                                        commentType: DataType.SCREENSHOTS,
                                                                    }
                                                                ));
                                                                setCommentsFetched(
                                                                    prevState => ({
                                                                        ...prevState,
                                                                        [image.id]: true,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        onMouseLeave={() =>
                                                        {
                                                            setCommentsFetched(
                                                                prevState => ({
                                                                    ...prevState,
                                                                    [image.id]: false,
                                                                })
                                                            );
                                                        }}
                                                        TransitionProps={{ timeout: 100 }}
                                                        title={tooltipContent(image)}
                                                        placement="right"
                                                        arrow
                                                    >
                                                        <SmsIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                                opacity: 0.5,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ) : null
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            sx={{
                                                justifyContent: 'space-between',
                                                marginTop: '0.5rem',
                                            }}
                                        >
                                            <Grid>
                                                <span>
                                                    {image.alertWords?.join(', ')}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            );
                        })
                    }
                </>
                {showMoveTop && (
                    <Fab
                        color="primary"
                        sx={{ position: 'absolute', bottom: showMoveBottom ? 100 : 25, right: 25 }}
                        onClick={() =>
                        {
                            setStartIndexLocation(0);
                            setEndIndexLocation(50);
                            imageContainer.current?.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                    >
                        <KeyboardArrowUpIcon />
                    </Fab>
                )}
                {showMoveBottom && (
                    <Fab
                        color="primary"
                        sx={{ position: 'absolute', bottom: 25, right: 25 }}
                        onClick={() =>
                        {
                            const imagesCount = images?.length ?? 0;
                            const endIndex = Math.ceil(imagesCount / pageOffsetValue) * pageOffsetValue;
                            const startIndex = endIndex > 50 ? endIndex - 50 : 0;
                            setStartIndexLocation(startIndex);
                            setEndIndexLocation(endIndex);
                            const container = imageContainer.current;
                            container?.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
                        }}
                    >
                        <KeyboardArrowDownIcon />
                    </Fab>
                )}
            </Grid>
        );
}
