import { FormControlLabel } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { ToggleSwitch } from './ToggleSwitch';
import { GalleryFilters } from 'src/models/captures/capturedData.model';
export interface ToggleButtonProps {
label:string,
checked?:boolean,
keyOfFilters: string,
onChange:(event:ChangeEvent<HTMLInputElement>, key:keyof GalleryFilters)=>void
}
export default function ToggleButton({
    checked,
    label,
    keyOfFilters,
    onChange}:Readonly<ToggleButtonProps>):JSX.Element
{
    return (
        <FormControlLabel
            key={label}
            control={(
                <ToggleSwitch
                    sx={{
                        m: 2,
                        '& .MuiSwitch-track': {
                            backgroundColor: 'darkgray',
                        },
                    }}
                    checked={checked}
                    onChange={(event:ChangeEvent<HTMLInputElement>) =>
                    {
                        onChange(
                            event,
                            keyOfFilters as keyof GalleryFilters
                        );
                    }}
                />
            )}
            name="activated"
            label={label}
        />
    );
}
