/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControlLabel, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SlideshowButtonStyle } from 'src/templates/photo-alerts-table/PhotosAlertsGrid';
import { ToggleSwitch } from '../toggle-switch/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAlertsFilter,
    changeFilters,
    selectAlertsFilter,
    selectFilters,
} from 'src/state/captures/capturesSlice';
import { DataType, Page } from 'src/models/dataType.enum';
import DataTypeUpdateFlagAll from 'src/templates/data-type-update-flag-all/DatatypeUpdateFlagAll';
import { CaptureFilters } from 'src/models/captures/capturedData.model';

export interface GalleryViewHeaderProps{
    dataType: DataType;
    count:number;
    page?:string;
}
export function GalleryViewHeader({
    dataType,
    count,
    page,
}: Readonly<GalleryViewHeaderProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const viewDataFilters = useSelector(selectFilters);
    const alertsFilter = useSelector(selectAlertsFilter);
    const [filters, setFilters] = useState<CaptureFilters>();
    useEffect(()=>
    {
        if(page === Page.ALERTS) setFilters({ ...alertsFilter });
        else setFilters({ ...viewDataFilters });
    }, [viewDataFilters, alertsFilter]);

    function handleAlertWordOnly():void
    {
        if(page === Page.ALERTS)
        {
            const afterChangeFilter = sessionStorage.getItem('AlertsFilter');
            let updatedFilters =alertsFilter.alertsdatatype && afterChangeFilter
                ? JSON.parse(afterChangeFilter)
                : alertsFilter;
            updatedFilters = {
                ...updatedFilters,
                alertwordsOnly: !alertsFilter.alertwordsOnly,
            };
            dispatch(changeAlertsFilter(updatedFilters));
        }
        else
        {
            dispatch(
                changeFilters({
                    alertwordsOnly:
                        !viewDataFilters.alertwordsOnly,
                })
            );
        }
    }

    return (

        <Grid item md={6} xs={12}>
            <Grid
                container
                spacing={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
            >
                {viewDataFilters.device && (
                    <Grid
                        item
                        md={4}
                        sm={12}
                        xs={12}
                    >
                        <DataTypeUpdateFlagAll
                            dataType={dataType}
                            count={count}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    md={4}
                    sm={12}
                    xs={12}
                >
                    <Button
                        variant="contained"
                        onClick={handleAlertWordOnly}
                    >
                        {!filters?.alertwordsOnly
                            ? t('form.View-alert-words')
                            : t('form.No-alert-words')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
